import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  collection,
  getDocs,
  serverTimestamp,
  updateDoc,
  doc,
  getFirestore,
  addDoc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import TablaCotizaciones from "./TablaCotizaciones";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import Modal from "react-modal";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
moment.locale("es");
// Establece la función de inicialización de react-modal para evitar un aviso de desenfoque de accesibilidad
Modal.setAppElement("#root");

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "20px",
    maxWidth: "800px",
    width: "100%",
    height: "500px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
    "::WebkitScrollbar": {
      width: "none",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

const customStyles2 = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "500px",
    padding: "20px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    border: "none",
    fontFamily: "Roboto, sans-serif",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  h2: {
    fontSize: "1.5rem",
    color: "#333",
    marginBottom: "20px",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  label: {
    fontSize: "1rem",
    color: "#666",
    marginBottom: "5px",
  },
  input: {
    padding: "10px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ddd",
    outline: "none",
    transition: "border-color 0.3s ease",
  },
  inputFocus: {
    borderColor: "#007bff",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "none",
    color: "#fff",
    backgroundColor: "#007bff",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    flex: "1", // Make buttons take equal space
    margin: "0 5px", // Space between buttons
  },
  buttonCancel: {
    backgroundColor: "#6c757d",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  buttonCancelHover: {
    backgroundColor: "#5a6268",
  },
};

function CotizacionForm(props) {
  const [numeroCotizacion, setNumeroCotizacion] = useState("");
  const [cliente, setCliente] = useState("");
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [asunto, setAsunto] = useState("");
  const [fechaCotizacion, setFechaCotizacion] = useState(new Date());
  const [fechaVencimiento, setFechaVencimiento] = useState(null);
  const [atencion, setAtencion] = useState("");
  const [planta, setPlanta] = useState("");
  const [divisa, setDivisa] = useState("MXN");
  const [area, setArea] = useState("");
  const [referencia, setReferencia] = useState("");
  const [terminos, setTerminos] = useState("");
  const [notas, setNotas] = useState("");
  const [vendedores, setVendedores] = useState([]);
  const [vendedorNombre, setVendedorNombre] = useState("");
  const [vendedorCorreo, setVendedorCorreo] = useState("");
  const [nuevoVendedorNombre, setNuevoVendedorNombre] = useState("");
  const [nuevoVendedorCorreo, setNuevoVendedorCorreo] = useState("");
  const [modalVendedorIsOpen, setModalVendedorIsOpen] = useState(false);
  const [datosSeleccionados, setDatosSeleccionados] = useState([
    {
      id: 1,
      nombre: "",
      modelo: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0,
      importe: 0,
    },
  ]);
  const [descuento, setDescuento] = useState(0);
  const [productosSeleccionados, setProductosSeleccionados] = useState([]);
  const [mostrarTabla, setMostrarTabla] = useState(false);
  const [mostrarPrevia, setMostrarPrevia] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { cotizacion, cotizaciones, setCotizaciones, onFormDirtyChange } =
    props;
  const [modoEdicion, setModoEdicion] = useState(false);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [formDirty, setFormDirty] = useState(false);
  const [events, setEvents] = useState([]); // Array para los eventos del calendario

  useEffect(() => {
    if (onFormDirtyChange) {
      onFormDirtyChange(formDirty);
    }
  }, [formDirty, onFormDirtyChange]);

  useEffect(() => {
    if (cotizacion) {
      setModoEdicion(true);
      // Llenar el formulario con los datos de la cotización, incluyendo el número de cotización
      setCliente(cotizacion.cliente);
      setReferencia(cotizacion.referencia);
      setFechaCotizacion(
        cotizacion.fechaCotizacion
          ? new Date(cotizacion.fechaCotizacion)
          
          : new Date()
      );
      setFechaVencimiento(
        cotizacion.fechaVencimiento
          ? new Date(cotizacion.fechaVencimiento)
          : null
      );
      setVendedorNombre(cotizacion.vendedorNombre || "");
      setVendedorCorreo(cotizacion.vendedorCorreo || "");
      setVendedores((prevVendedores) => {
        const vendedorExiste = prevVendedores.some(
          (vendedor) => vendedor.nombre === cotizacion.vendedorNombre
        );
        if (!vendedorExiste && cotizacion.vendedorNombre) {
          return [
            ...prevVendedores,
            {
              nombre: cotizacion.vendedorNombre,
              correo: cotizacion.vendedorCorreo,
            },
          ];
        }
        return prevVendedores;
      });
      setAtencion(cotizacion.atencion);
      setPlanta(cotizacion.planta);
      setArea(cotizacion.area);
      setAsunto(cotizacion.asunto);
      setProductosSeleccionados(cotizacion.productosSeleccionados);
      setDatosSeleccionados(cotizacion.productosSeleccionados);
      setDescuento(cotizacion.descuento || 0);
      setNumeroCotizacion(cotizacion.numeroCotizacion); // Aquí se asigna el número de cotización al estado
      setNotas(cotizacion.notas);
      setTerminos(cotizacion.terminos);
      // Resto de los campos...
    } else {
      obtenerNumeroCotizacion();
    }
  }, [cotizacion]);

  // Función para cargar productos reales desde Firestore
  useEffect(() => {
    const fetchProductos = async () => {
      try {
        const productosSnapshot = await getDocs(collection(db, "productos"));
        const productosList = productosSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProductos(productosList);
      } catch (error) {
        console.error("Error al cargar los productos desde Firestore", error);
      }
    };

    fetchProductos();
  }, []);

  const handleCancel = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          // Recargar la página al confirmar la salida
          window.location.reload();
        }
      });
    } else {
      // Si no hay cambios, salir sin confirmación
      window.location.reload(); // Recargar la página directamente si no hay cambios
    }
  };

  const handleInputChange = (e, productoId) => {
    const nombreProducto = e.target.value;
    setFormDirty(true); // Marcar que el formulario ha sido modificado

    // Encuentra el producto en la lista de productos reales desde Firestore
    const productoEncontrado = productos.find(
      (prod) => prod.nombre === nombreProducto
    );

    if (productoEncontrado) {
      setDatosSeleccionados((prevDatos) => {
        const currentItem = prevDatos.find((item) => item.id === productoId);
        const existingItem = prevDatos.find(
          (item) => item.nombre === nombreProducto && item.id !== productoId
        );

        if (existingItem) {
          // Si el producto ya existe, sumamos las cantidades y actualizamos el importe sin IVA
          const nuevaCantidad = existingItem.cantidad + currentItem.cantidad;
          return prevDatos
            .map((item) => {
              if (item.id === existingItem.id) {
                return {
                  ...item,
                  cantidad: nuevaCantidad,
                  importe: productoEncontrado.costo * nuevaCantidad, // Calcula el importe sin IVA
                };
              } else {
                return item;
              }
            })
            .filter((item) => item.id !== productoId); // Eliminamos el ítem duplicado
        } else {
          // Si el producto no existe, actualizamos el ítem actual sin IVA
          return prevDatos.map((item) =>
            item.id === productoId
              ? {
                  ...item,
                  nombre: productoEncontrado.nombre,
                  numeroDeParte: productoEncontrado.numeroDeParte,
                  tarifa: productoEncontrado.costo,
                  iva: 0.16, // Se mantiene el valor de IVA, pero no se aplica al importe aquí
                  importe: productoEncontrado.costo * item.cantidad, // Calcula el importe sin IVA
                }
              : item
          );
        }
      });
    } else {
      // Si el producto no se encuentra, solo actualiza el nombre
      setDatosSeleccionados((prevDatos) =>
        prevDatos.map((item) =>
          item.id === productoId ? { ...item, nombre: nombreProducto } : item
        )
      );
    }
  };

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar y ordenar cotizaciones
          const proximas = cotizaciones
            .filter((cotizacion) =>
              moment(cotizacion.fechaVencimiento).isSameOrAfter(
                moment().startOf("day")
              )
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas

          // Crear eventos para cotizaciones
          const cotizacionesEvents = proximas.map((cotizacion) => ({
            id: `cotizacion-${cotizacion.id}`,
            title: `Cotización #${cotizacion.numeroCotizacion}`,
            start: moment(cotizacion.fechaVencimiento).startOf("day").toDate(),
            end: moment(cotizacion.fechaVencimiento).endOf("day").toDate(),
            allDay: true,
            resource: "cotizacion",
            style: {
              backgroundColor: "blue",
              padding: "auto",
              fontSize: "12px",
              width: "80%",
              margin: "0 auto",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto",
            },
          }));

          // Actualizar el estado de eventos
          setEvents((prevEvents) => {
            const eventMap = new Map(
              prevEvents
                .filter((event) => !event.id.startsWith("cotizacion-"))
                .map((event) => [event.id, event])
            );
            cotizacionesEvents.forEach((event) =>
              eventMap.set(event.id, event)
            );
            return Array.from(eventMap.values());
          });
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar y ordenar eventos
        const proximos = eventos
          .filter((evento) =>
            moment(evento.to).isSameOrAfter(moment().startOf("day"))
          )
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Crear eventos para el calendario
        const eventosCalendario = proximos.map((evento) => ({
          id: `evento-${evento.id}`,
          title: evento.title,
          start: moment(evento.to).startOf("day").toDate(),
          end: moment(evento.to).endOf("day").toDate(),
          allDay: true,
          resource: "evento",
          style: {
            backgroundColor: "#229954",
            padding: "auto",
            fontSize: "12px",
            width: "80%",
            margin: "0 auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "auto",
          },
        }));

        // Actualizar el estado de eventos
        setEvents((prevEvents) => {
          const eventMap = new Map(
            prevEvents
              .filter((event) => !event.id.startsWith("evento-"))
              .map((event) => [event.id, event])
          );
          eventosCalendario.forEach((event) => eventMap.set(event.id, event));
          return Array.from(eventMap.values());
        });
      });

      return () => unsubscribeEventos();
    };

    // Obtener fechas festivas
    const getFechasFestivas = () => {
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        {
          title: "Día de la Constitución",
          month: "02",
          day: "05",
          color: "#de2e03",
        },
        {
          title: "Natalicio de Benito Juárez",
          month: "03",
          day: "21",
          color: "#de2e03",
        },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        {
          title: "Independencia de México",
          month: "09",
          day: "16",
          color: "#de2e03",
        },
        {
          title: "Transición del Poder Ejecutivo",
          month: "10",
          day: "01",
          color: "#de2e03",
        },
        {
          title: "Revolución Mexicana",
          month: "11",
          day: "20",
          color: "#de2e03",
        },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      const generarFechasFestivas = (años) => {
        const fechasFestivas = [];
        años.forEach((año) => {
          fechasFestivasBase.forEach((festivo) => {
            const fecha = moment(
              `${año}-${festivo.month}-${festivo.day}`,
              "YYYY-MM-DD"
            ).toDate();
            fechasFestivas.push({
              id: `festivo-${festivo.title}-${año}`,
              title: festivo.title,
              start: fecha,
              end: fecha,
              allDay: true,
              resource: "festivo",
              style: {
                backgroundColor: "#de2e03",
                padding: "auto",
                fontSize: "12px",
                width: "80%",
                margin: "0 auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "auto",
              },
            });
          });
        });
        return fechasFestivas;
      };

      const años = Array.from(
        { length: 6 },
        (_, i) => new Date().getFullYear() + i
      );
      return generarFechasFestivas(años);
    };

    // Obtener fechas festivas y fusionarlas con los eventos existentes
    const fechasFestivas = getFechasFestivas();
    setEvents((prevEvents) => [
      ...prevEvents.filter((event) => !event.id.startsWith("festivo-")),
      ...fechasFestivas,
    ]);

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  const obtenerNumeroCotizacion = async () => {
    try {
      // Si deseas reiniciar el número de cotización a 0001, omite la consulta a Firestore.
      let siguienteNumero = 1;

      // Asegúrate de que el número esté formateado con 4 dígitos
      const numeroCotizacionFormateado = siguienteNumero
        .toString()
        .padStart(4, "0");

      // Establecer el número de cotización en el estado
      setNumeroCotizacion(numeroCotizacionFormateado);

      // En caso de que quieras incrementar el número de cotización en algún momento,
      // puedes almacenar este valor en Firestore o en un estado global para el futuro.
    } catch (error) {
      console.error("Error al obtener el número de cotización:", error);
    }
  };

  useEffect(() => {
    obtenerClientes();
    obtenerProductos();
  }, []);

  const handleSelectChange = (e) => {
    if (e.target.value === "agregar-nuevo") {
      setVendedorNombre("");
      setVendedorCorreo("");
      openModalVendedor();
    } else {
      const selectedVendedor = vendedores.find(
        (vendedor) => vendedor.nombre === e.target.value
      );
      if (selectedVendedor) {
        setVendedorNombre(selectedVendedor.nombre);
        setVendedorCorreo(selectedVendedor.correo);
      } else {
        setVendedorNombre(e.target.value);
        setVendedorCorreo("");
      }
    }
  };

  const openModalVendedor = () => {
    if (vendedorNombre && vendedorNombre !== "agregar-nuevo") {
      // Editar vendedor existente
      setNuevoVendedorNombre(vendedorNombre);
      setNuevoVendedorCorreo(vendedorCorreo);
    } else {
      // Agregar nuevo vendedor
      setNuevoVendedorNombre("");
      setNuevoVendedorCorreo("");
    }
    setModalVendedorIsOpen(true);
  };

  const handleAgregarVendedor = () => {
    if (nuevoVendedorNombre.trim() !== "") {
      const existingVendedorIndex = vendedores.findIndex(
        (vendedor) => vendedor.nombre === vendedorNombre
      );

      if (existingVendedorIndex !== -1) {
        // Actualizar vendedor existente
        const updatedVendedor = {
          nombre: nuevoVendedorNombre,
          correo: nuevoVendedorCorreo,
        };

        const updatedVendedores = [...vendedores];
        updatedVendedores[existingVendedorIndex] = updatedVendedor;
        setVendedores(updatedVendedores);

        // Actualizar el vendedor actual
        setVendedorNombre(nuevoVendedorNombre);
        setVendedorCorreo(nuevoVendedorCorreo);

        Swal.fire({
          icon: "success",
          title: "Vendedor actualizado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // Agregar nuevo vendedor
        const nuevoVendedor = {
          nombre: nuevoVendedorNombre,
          correo: nuevoVendedorCorreo,
        };

        setVendedores([...vendedores, nuevoVendedor]);

        // Establecer el vendedor actual
        setVendedorNombre(nuevoVendedorNombre);
        setVendedorCorreo(nuevoVendedorCorreo);

        Swal.fire({
          icon: "success",
          title: "Vendedor guardado correctamente",
          showConfirmButton: false,
          timer: 1500,
        });
      }

      // Limpiar y cerrar el modal
      setNuevoVendedorNombre("");
      setNuevoVendedorCorreo("");
      setModalVendedorIsOpen(false);
    }
  };

  const closeModalVendedor = () => {
    setModalVendedorIsOpen(false);
    setNuevoVendedorNombre("");
    setNuevoVendedorCorreo("");
  };

  const cerrarModalPrevia = () => {
    setMostrarPrevia(false);
    setModalIsOpen(false);
  };

  const calcularTotal = () => {
    const subtotal = calcularSubtotal();
    const descuentoAplicado = subtotal * (descuento / 100); // Aplicar el descuento
    const subtotalConDescuento = subtotal - descuentoAplicado;
    const iva = subtotalConDescuento * obtenerIVA(); // Aplicar el IVA al subtotal con descuento
    return subtotalConDescuento + iva; // Total = subtotal con descuento + IVA
  };

  const obtenerIVA = () => {
    const ivaSeleccionado =
      datosSeleccionados.length > 0 ? datosSeleccionados[0].iva : 0.16;
    return ivaSeleccionado;
  };

  const manejarCambioDescuento = (e) => {
    let valor = e.target.value;

    // Permitir solo números y un solo punto decimal
    if (/^\d*\.?\d*$/.test(valor)) {
      let valorNumerico = parseFloat(valor);

      // Limitar el descuento a 100 como máximo
      if (valorNumerico > 100) {
        valorNumerico = 100;
      }

      // Si el campo está vacío, resetea el valor
      if (isNaN(valorNumerico)) {
        setDescuento("");
      } else {
        setDescuento(valorNumerico.toFixed(2)); // Mantén el formato de 2 decimales
      }
    }
  };

  const obtenerClientes = async () => {
    try {
      const clientesSnapshot = await getDocs(collection(db, "clientes"));
      const listaClientes = clientesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClientes(listaClientes);
    } catch (error) {
      console.error("Error al obtener clientes:", error);
    }
  };

  const obtenerProductos = async () => {
    try {
      const productosSnapshot = await getDocs(collection(db, "productos"));
      const listaProductos = productosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        tipoDescuento: "cantidad",
        valorDescuento: 0,
      }));
      setProductos(listaProductos);
    } catch (error) {
      console.error("Error al obtener los productos:", error);
    }
  };

  const continuarDesdePrevia = () => {
    setMostrarTabla(true);
  };

  /*function agregarProducto() {
    if (productoSeleccionado) {
      const producto = productos.find(p => p.id === productoSeleccionado);
      if (producto) {
        setProductosSeleccionados([...productosSeleccionados, { ...producto, productoIdEditado: producto.id }]);
        setProductoSeleccionado('');
      }
    }
  }*/

  const eliminarProducto = (id) => {
    // Aquí colocas la lógica para eliminar el producto basado en el id
    // Por ejemplo, puedes filtrar el array de productos para eliminar el producto con el id especificado
    setDatosSeleccionados(
      datosSeleccionados.filter((producto) => producto.id !== id)
    );
  };

  const actualizarCantidad = (productoId, nuevaCantidad) => {
    setDatosSeleccionados((prevDatos) =>
      prevDatos.map((item) =>
        item.id === productoId
          ? {
              ...item,
              cantidad: nuevaCantidad,
              importe: item.tarifa * nuevaCantidad, // Solo calcula el importe sin aplicar IVA aquí
            }
          : item
      )
    );
  };

  const actualizarCotizacionExistente = async (
    cotizacionId,
    cotizacionData
  ) => {
    try {
      // Aquí debes realizar la lógica para actualizar la cotización existente en tu base de datos
      // Por ejemplo, podrías utilizar Firebase Firestore para actualizar el documento de la cotización

      // Primero, obtén la referencia al documento de la cotización que deseas actualizar
      const cotizacionRef = doc(db, "cotizaciones", cotizacionId);

      // Luego, utiliza la función `updateDoc` para actualizar los datos de la cotización
      await updateDoc(cotizacionRef, cotizacionData);

      // Si la actualización se realiza correctamente, no es necesario hacer nada más
    } catch (error) {
      // Manejo de errores
      console.error("Error al actualizar la cotización:", error);
      throw error; // Puedes lanzar el error para que sea manejado por la función `guardar`
    }
  };

  const guardar = async (e) => {
    e.preventDefault();

    // Verifica que los campos obligatorios estén llenos
    if (!cliente || !numeroCotizacion || !fechaCotizacion || !atencion) {
      Swal.fire({
        icon: "error",
        title: "Campos obligatorios faltantes",
        text: "Por favor completa los campos: Nombre del cliente, No. Cotización, Fecha de cotización, y Atención.",
      });
      return; // No continuar con la función si los campos requeridos no están completos
    }

    try {
      const clienteSeleccionado = clientes.find((c) => c.id === cliente);
      const nombreCliente = clienteSeleccionado?.empresa || "";

      const cotizacionData = {
        cliente,
        numeroCotizacion,
        nombreCliente,
        referencia,
        fechaCotizacion: fechaCotizacion ? fechaCotizacion.toISOString() : null,
        fechaVencimiento: fechaVencimiento
          ? fechaVencimiento.toISOString()
          : null,
        vendedorNombre,
        vendedorCorreo,
        atencion,
        planta,
        area,
        divisa,
        asunto,
        productosSeleccionados: datosSeleccionados,
        total: calcularTotal(),
        notas,
        terminos,
        descuento,
        createdAt: serverTimestamp(),
        estado: "Pendiente",
      };

      if (modoEdicion && cotizacion && cotizacion.id) {
        // Actualizar cotización existente
        await actualizarCotizacionExistente(cotizacion.id, cotizacionData);

        // Actualiza la lista de cotizaciones localmente
        if (typeof setCotizaciones === "function") {
          setCotizaciones((prev) =>
            prev.map((c) =>
              c.id === cotizacion.id
                ? { id: cotizacion.id, ...cotizacionData }
                : c
            )
          );
        }

        Swal.fire({
          icon: "success",
          title: "Cotización actualizada correctamente",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      } else {
        // Crear nueva cotización
        const docRef = await addDoc(
          collection(db, "cotizaciones"),
          cotizacionData
        );

        if (typeof setCotizaciones === "function") {
          setCotizaciones((prev) => [
            ...prev,
            { id: docRef.id, ...cotizacionData },
          ]);
        }

        Swal.fire({
          icon: "success",
          title: "Cotización guardada correctamente",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("Error al guardar la cotización:", error);
      Swal.fire({
        icon: "error",
        title: "Error al guardar la cotización",
        text: error.message,
      });
    }
  };

  const actualizarIVA = (productoId, nuevoIVA) => {
    setDatosSeleccionados((prevDatos) =>
      prevDatos.map((item) =>
        item.id === productoId
          ? {
              ...item,
              iva: nuevoIVA, // Actualizamos el valor del IVA, pero no lo aplicamos al importe
              importe: item.tarifa * item.cantidad, // Calcula el importe sin aplicar IVA
            }
          : item
      )
    );
  };

  const añadirFila = (e) => {
    e.preventDefault(); // Evita el comportamiento por defecto del botón
    const nuevaFila = {
      id: datosSeleccionados.length + 1,
      nombre: "",
      numeroDeParte: "",
      cantidad: 1,
      tarifa: 0,
      iva: 0,
      importe: 0,
    };

    setDatosSeleccionados((prevDatos) => [...prevDatos, nuevaFila]);
  };

  const agregarArticulosAGranel = (articulos) => {
    // Lógica para agregar artículos a granel
    console.log("Agregando artículos a granel:", articulos);
  };

  const calcularSubtotal = () => {
    return datosSeleccionados.reduce(
      (total, producto) => total + producto.tarifa * producto.cantidad,
      0
    );
  };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
        events={events}
      />

      <Sidebar />

      <div className="cotizacion-form">
        <form>
          <div>
            <div className="group apartado">
              <label htmlFor="cliente" className="required">
                Nombre del cliente*
              </label>
              <select
                id="cliente"
                name="cliente"
                value={cliente}
                onChange={(e) => {
                  setCliente(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              >
                <option value="">Seleccionar cliente</option>
                {clientes.map((cliente) => (
                  <option key={cliente.id} value={cliente.id}>
                    {cliente.empresa}
                  </option>
                ))}
              </select>
            </div>

            <br />

            <div className="group">
              <label htmlFor="numeroCotizacion" className="required">
                No. Cotización#*
              </label>
              <input
                type="text"
                id="numeroCotizacion"
                name="numeroCotizacion"
                value={numeroCotizacion}
                onChange={(e) => {
                  setNumeroCotizacion(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              />
            </div>

            <div className="group">
              <label htmlFor="referencia">Referencia</label>
              <input
                type="text"
                id="referencia"
                name="referencia"
                value={referencia}
                onChange={(e) => {
                  setReferencia(e.target.value);
                  setFormDirty(true); // Marcar que el formulario ha sido modificado
                }}
              />
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="fecha-cotizacion" className="required">
                  Fecha de cotización*
                </label>
                <input
                  type="text"
                  id="fecha-cotizacion"
                  name="fecha-cotizacion"
                  value={fechaCotizacion ? formatDate(fechaCotizacion) : ""}
                  readOnly
                />
              </div>
              <div className="group">
                <label htmlFor="fecha-vencimiento">Fecha de vencimiento</label>
                <div>
                  <DatePicker
                    selected={fechaVencimiento}
                    onChange={(date) => {
                      setFechaVencimiento(date);
                      setFormDirty(true); // Marcar el formulario como modificado
                    }}
                    dateFormat="dd MMM yyyy"
                    placeholderText="dd MMM yyyy"
                    id="fecha-vencimiento"
                  />
                </div>
              </div>
            </div>

            <hr
              style={{
                marginBottom: "30px",
                marginTop: "20px",
                border: "1px solid #f9f9fb",
              }}
            />

            <div className="group">
              <label htmlFor="vendedorNombre">Vendedor</label>
              <select
                id="vendedorNombre"
                name="vendedorNombre"
                value={vendedorNombre}
                onChange={(e) => {
                  handleSelectChange(e);
                  setFormDirty(true); // Marcar el formulario como modificado
                }}
              >
                <option value="">Seleccionar o agregar vendedor</option>
                {vendedores.map((vendedor, index) => (
                  <option key={index} value={vendedor.nombre}>
                    {vendedor.nombre}
                  </option>
                ))}
                <option value="agregar-nuevo">Agregar nuevo vendedor</option>
              </select>
              <br />

              {/* Modal para agregar o editar vendedor */}
              <Modal
                isOpen={modalVendedorIsOpen}
                onRequestClose={closeModalVendedor}
                contentLabel={
                  vendedorNombre && vendedorNombre !== "agregar-nuevo"
                    ? "Editar Vendedor"
                    : "Agregar Nuevo Vendedor"
                }
                style={customStyles2}
              >
                <h2 style={customStyles2.h2}>
                  {vendedorNombre && vendedorNombre !== "agregar-nuevo"
                    ? "Editar Vendedor"
                    : "Agregar Nuevo Vendedor"}
                </h2>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleAgregarVendedor();
                  }}
                  style={customStyles2.form}
                >
                  <label
                    htmlFor="nuevoVendedorNombre"
                    style={customStyles2.label}
                  >
                    Nombre (obligatorio)
                  </label>
                  <input
                    type="text"
                    id="nuevoVendedorNombre"
                    name="nuevoVendedorNombre"
                    value={nuevoVendedorNombre}
                    onChange={(e) => setNuevoVendedorNombre(e.target.value)}
                    required
                    style={customStyles2.input}
                  />
                  <br />
                  <label
                    htmlFor="nuevoVendedorCorreo"
                    style={customStyles2.label}
                  >
                    Correo
                  </label>
                  <input
                    type="email"
                    id="nuevoVendedorCorreo"
                    name="nuevoVendedorCorreo"
                    value={nuevoVendedorCorreo}
                    onChange={(e) => setNuevoVendedorCorreo(e.target.value)}
                    style={customStyles2.input}
                  />
                  <br />
                  <div style={customStyles2.buttonContainer}>
                    <button
                      type="button"
                      onClick={closeModalVendedor}
                      style={{
                        ...customStyles2.button,
                        ...customStyles2.buttonCancel,
                      }}
                    >
                      Cancelar
                    </button>
                    <button type="submit" style={customStyles2.button}>
                      {vendedorNombre && vendedorNombre !== "agregar-nuevo"
                        ? "Actualizar"
                        : "Guardar"}
                    </button>
                  </div>
                </form>
              </Modal>
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="atencion" className="required">
                  Atención*
                </label>
                <input
                  type="text"
                  id="atencion"
                  name="atencion"
                  value={atencion}
                  onChange={(e) => {
                    setAtencion(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>
              <div className="group">
                <label htmlFor="planta">Planta</label>
                <input
                  type="text"
                  id="planta"
                  name="planta"
                  value={planta}
                  onChange={(e) => {
                    setPlanta(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>
            </div>

            <div className="group fechas">
              <div className="group">
                <label htmlFor="area">Área</label>
                <input
                  type="text"
                  id="area"
                  name="area"
                  value={area}
                  onChange={(e) => {
                    setArea(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>
              <div className="group">
                <label htmlFor="divisa">Divisa</label>
                <select
                  id="divisa"
                  name="divisa"
                  value={divisa}
                  onChange={(e) => {
                    setDivisa(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                >
                  <option value="MXN">MXN</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>

            <br />

            <div className="group">
              <label htmlFor="asunto">Asunto</label>
              <input
                type="text"
                id="asunto"
                name="asunto"
                value={asunto}
                onChange={(e) => {
                  setAsunto(e.target.value);
                  setFormDirty(true); // Marcar el formulario como modificado
                }}
              />
            </div>

            <br />

            <div className="apartado2">Tabla de productos</div>

            <div>
              {/* Contenedor para la tabla y los botones */}
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                }}
              >
                {/* Tabla general */}
                <div>
                  <div>
                    <table
                      className="productos-table"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>Detalles del Artículo</th>
                          <th>Modelo</th>
                          <th>Cantidad</th>
                          <th>Precio</th>
                          <th>IVA</th>
                          <th>Importe</th>
                        </tr>
                      </thead>
                      <tbody>
                        {datosSeleccionados.map((producto) => (
                          <tr key={producto.id}>
                            <td>
                              <input
                                id="list-products"
                                list="productos-list"
                                value={producto.nombre || ""}
                                onChange={(e) => {
                                  handleInputChange(e, producto.id);
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                                placeholder="Escriba o haga clic para seleccionar un artículo"
                                style={{ width: "400px", height: "90px" }}
                                autoComplete="off"
                              />
                              <datalist id="productos-list">
                                {productos.map((prod) => (
                                  <option key={prod.id} value={prod.nombre} />
                                ))}
                              </datalist>
                            </td>
                            <td>
                              <input
                                type="text"
                                value={producto.numeroDeParte || ""}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={producto.cantidad || 1}
                                onChange={(e) => {
                                  actualizarCantidad(
                                    producto.id,
                                    parseInt(e.target.value)
                                  );
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                value={producto.tarifa || 0}
                                onChange={(e) => {
                                  const nuevoPrecio =
                                    parseFloat(e.target.value) || 0;
                                  setDatosSeleccionados((prevDatos) =>
                                    prevDatos.map((item) =>
                                      item.id === producto.id
                                        ? {
                                            ...item,
                                            tarifa: nuevoPrecio,
                                            importe:
                                              nuevoPrecio * item.cantidad,
                                          }
                                        : item
                                    )
                                  );
                                }}
                              />
                            </td>
                            <td>
                              <select
                                value={producto.iva || 16}
                                onChange={(e) => {
                                  actualizarIVA(
                                    producto.id,
                                    parseFloat(e.target.value)
                                  );
                                  setFormDirty(true); // Marcar el formulario como modificado
                                }}
                              >
                                <option value="0">0%</option>
                                <option value="0.08">8%</option>
                                <option value="0.16">16%</option>
                              </select>
                            </td>
                            <td
                              style={{
                                textAlign: "right",
                                paddingRight: "10px",
                              }}
                            >
                              $
                              {producto.importe.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) || "0.00"}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Contenedor para los botones */}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-end",
                    marginLeft: "10px",
                  }}
                >
                  {datosSeleccionados.map((producto) => (
                    <div
                      key={producto.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "100px",
                      }}
                    >
                      {/* Botón de más opciones */}
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                          color: "#000",
                        }}
                      >
                        <i
                          className="bi bi-three-dots-vertical"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      </button>

                      {/* Botón de eliminar */}
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          padding: "0",
                          cursor: "pointer",
                          color: "#dc3545", // Color rojo para el ícono
                        }}
                        onClick={() => eliminarProducto(producto.id)}
                      >
                        <i
                          className="bi bi-x-lg"
                          style={{ fontSize: "1.2rem" }}
                        ></i>
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="acciones-tabla">
              <button onClick={añadirFila}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Añadir nueva fila
              </button>
              <button onClick={agregarArticulosAGranel}>
                <i
                  style={{ color: "#007bff", fontSize: "16px" }}
                  className="bi bi-plus-circle-fill"
                ></i>
                Agregar artículos a granel
              </button>
            </div>

            <div className="data-container">
              <div className="notes-container">
                <label htmlFor="customer-notes">Notas del Cliente</label>
                <textarea
                  id="customer-notes"
                  placeholder="Escribe tus notas aquí..."
                  value={notas}
                  onChange={(e) => {
                    setNotas(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                ></textarea>
              </div>

              <div className="totales-container">
                <div className="row">
                  <span className="label">Subtotal</span>
                  <span>
                    $
                    {calcularSubtotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <div className="row descuento">
                  <span className="label">Descuento</span>
                  <div className="descuento-input-container">
                    <input
                      type="text"
                      style={{
                        textAlign: "right",
                        width: "60px",
                        paddingRight: "5px",
                        fontFamily: "monospace",
                      }}
                      value={descuento}
                      onChange={manejarCambioDescuento}
                    />
                    <span className="percentage-sign">%</span>
                  </div>
                  <span className="discount-percentage">
                    -
                    {descuento
                      ? `${((calcularSubtotal() * descuento) / 100).toFixed(2)}`
                      : "0.00"}
                  </span>
                </div>

                <div className="row iva">
                  <span className="label">
                    Tipo estándar [{(obtenerIVA() * 100).toFixed(0)}%]
                  </span>
                  <span>
                    $
                    {(
                      (calcularSubtotal() - descuento) *
                      obtenerIVA()
                    ).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>

                <hr />

                <div className="row total">
                  <span>Total ({divisa})</span>
                  <span className="total-amount">
                    $
                    {calcularTotal().toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "#f9f9fb",
                padding: "8px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                gap: "10px",
                width: "94%",
                marginTop: "30px",
                paddingBottom: "30px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              {/* Left Section: Términos y Condiciones */}
              <div className="terminos-container">
                <h4>Términos y condiciones</h4>
                <textarea
                  value={terminos}
                  onChange={(e) => {
                    setTerminos(e.target.value);
                    setFormDirty(true); // Marcar el formulario como modificado
                  }}
                />
              </div>

              {/* Right Section: File Upload */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                  flex: "0 0 530px",
                  marginTop: "30px",
                }}
              >
                <label style={{ fontSize: "14px" }}>
                  Adjuntar archivo(s) a Estimación
                </label>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px dashed #ccc",
                    borderRadius: "5px",
                    overflow: "hidden", // Eliminar cualquier contenido que se salga del borde
                  }}
                >
                  <label
                    style={{
                      padding: "8px 10px",
                      backgroundColor: "#fff",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      cursor: "pointer",
                      whiteSpace: "nowrap", // Evitar que el contenido del botón se ajuste en varias líneas
                    }}
                  >
                    <i style={{ color: "#000" }} className="bi bi-upload"></i>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#000",
                        fontWeight: "lighter",
                      }}
                    >
                      Cargar archivo
                    </span>
                    <input
                      type="file"
                      style={{ display: "none" }} // Ocultar el input de archivo
                      multiple
                    />
                  </label>

                  <button
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      borderLeft: "1px dashed #ccc", // Borde que conecta con el input
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      cursor: "pointer",
                      borderTopRightRadius: "auto", // Bordes redondeados en la esquina derecha
                      borderBottomRightRadius: "auto",
                      width: "10px", // Dejar que el botón ajuste su tamaño
                      minWidth: "40px", // Evitar que el botón se haga demasiado pequeño
                      margin: "0", // Eliminar cualquier margen que agregue espacio extra
                    }}
                  >
                    <i
                      style={{
                        color: "#000",
                        fontSize: "14px", // Ajustar el tamaño del ícono
                      }}
                      className="bi bi-chevron-down"
                    ></i>
                  </button>
                </div>

                <p style={{ fontSize: "12px", color: "#888" }}>
                  Puede cargar un máximo de 3 archivos, cada uno de 10 MB
                </p>
              </div>
            </div>
          </div>

          {/* Grupo de botones: Guardar como borrador, Guardar y enviar, Cancelar */}
          <div className="cotizacion-toolbar">
            <div className="btn-group">
              <button
                id="guardar-enviar"
                className="btn btn-primary"
                type="submit"
                onClick={guardar}
              >
                {modoEdicion ? "Actualizar" : "Guardar y enviar"}
              </button>
              <button
                id="cancelar"
                className="btn btn-secondary"
                type="button"
                onClick={handleCancel}
              >
                Cancelar
              </button>
            </div>
          </div>
        </form>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {mostrarPrevia && (
            <PreviaCotizacion
              cliente={cliente}
              clientes={clientes}
              referencia={referencia}
              asunto={asunto}
              fechaCotizacion={fechaCotizacion}
              productosSeleccionados={productosSeleccionados}
              continuarDesdePrevia={continuarDesdePrevia}
              numeroCotizacion={props.numeroCotizacion}
            />
          )}
          <div className="modal-buttons">
            <button onClick={cerrarModalPrevia} className="eliminarBtnModal">
              Cerrar
            </button>
          </div>
        </Modal>

        {mostrarTabla && (
          <TablaCotizaciones
            numeroCotizacion={numeroCotizacion}
            cotizaciones={cotizaciones}
          />
        )}
      </div>
    </>
  );
}

function PreviaCotizacion({
  productosSeleccionados, // Aquí no asignamos un valor por defecto, pero lo manejamos luego
}) {
  // Validación para asegurarse de que productosSeleccionados sea un arreglo
  if (!Array.isArray(productosSeleccionados)) {
    console.error(
      "productosSeleccionados está indefinido o no es un arreglo:",
      productosSeleccionados
    );
    productosSeleccionados = []; // Establece un arreglo vacío como respaldo
  }
}

export default CotizacionForm;