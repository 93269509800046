import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import CotizacionForm from "./CotizacionForm";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";

Modal.setAppElement("#root");

const BandejaCotizaciones = ({
  cotizaciones,
  setCotizaciones,
  selectedCotizacionId,
  onRowClick,
  guardarCotizacion,
  cotizacion,
  clientes,
}) => {
  const itemsPerPageOptions = [10, 25, 50, 100];
  const [filteredCotizaciones, setFilteredCotizaciones] =
    useState(cotizaciones);
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showOptions, setShowOptions] = useState(false); // Opciones del botón de "Nuevo"
  const [showBulkActionsOptions, setShowBulkActionsOptions] = useState(false); // Opciones de "Marcar como enviado"
  const [selectedCotizaciones, setSelectedCotizaciones] = useState([]); // Estado para las cotizaciones seleccionadas
  const [selectAll, setSelectAll] = useState(false); // Checkbox genérico (seleccionar todos)
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const firestore = getFirestore();
    const cotizacionesRef = collection(firestore, "cotizaciones");

    const unsubscribe = onSnapshot(cotizacionesRef, (snapshot) => {
      const cotizacionesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Ordenar las cotizaciones por fecha de creación (más recientes primero)
      cotizacionesData.sort(
        (a, b) => new Date(b.fechaCotizacion) - new Date(a.fechaCotizacion)
      );

      setCotizaciones(cotizacionesData);
      setFilteredCotizaciones(cotizacionesData); // Actualizar la lista filtrada también
    });

    return () => unsubscribe(); // Limpieza del listener al desmontar el componente
  }, [setCotizaciones]);

  const totalPages = Math.ceil(filteredCotizaciones.length / itemsPerPage);

  const paginatedCotizaciones = filteredCotizaciones.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (direction) => {
    if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    const filtered = cotizaciones.filter((cotizacion) => {
      const formattedImporte = parseFloat(cotizacion?.total)
        ?.toFixed(2)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return (
        cotizacion.nombreCliente
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        cotizacion.fechaCotizacion.includes(searchTerm) ||
        cotizacion.numeroCotizacion
          .toString()
          .padStart(4, "0")
          .includes(searchTerm) ||
        formattedImporte.includes(searchTerm)
      );
    });
    setFilteredCotizaciones(filtered);
    setCurrentPage(1);
  };

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, "0");
    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString().slice(-2);
    return `${day} ${month} ${year}`;
  }

  const openFormulario = () => {
    setIsFormDirty(false);
    setShowModal(true);
  };

  const closeModal = () => {
    if (isFormDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          setShowModal(false);
          setIsFormDirty(false); // Restablecer el estado formDirty
        }
      });
    } else {
      setShowModal(false);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const toggleBulkActionsOptions = () => {
    setShowBulkActionsOptions(!showBulkActionsOptions);
  };

  const handleSelectCotizacion = (cotizacionId) => {
    setSelectedCotizaciones((prevSelected) =>
      prevSelected.includes(cotizacionId)
        ? prevSelected.filter((id) => id !== cotizacionId)
        : [...prevSelected, cotizacionId]
    );
  };

  // Checkbox genérico - seleccionar todos
  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedCotizaciones([]); // Deselecciona todo
    } else {
      setSelectedCotizaciones(cotizaciones.map((cot) => cot.id)); // Selecciona todo
    }
    setSelectAll(!selectAll);
  };

  const showBulkActions = selectedCotizaciones.length > 0;

  const exportarPDF = () => {
    console.log("Exportar como PDF");
  };

  const imprimir = () => {
    console.log("Imprimir cotización");
  };

  const eliminar = () => {
    console.log("Eliminar cotización");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".bulk-actions-right")) {
        setShowBulkActionsOptions(false);
      }
    };
    if (showBulkActionsOptions) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => document.removeEventListener("click", handleClickOutside);
  }, [showBulkActionsOptions]);

  return (
    <div className="cotizaciones-bandeja">
      <div className="bandeja-search-form">
        <div className="bandeja-search-input-wrapper">
          <i className="bi bi-search"></i>
          <input
            type="text"
            className="bandeja-search-input"
            placeholder="Buscar..."
            onChange={handleSearch}
          />
        </div>
      </div>

      {/* Mostrar encabezado condicional con las opciones de acciones en lote */}
      {showBulkActions && (
        <div className="bulk-actions">
          <div className="bulk-actions-left">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
              style={{ marginTop: "0", alignSelf: "center" }} // Ajuste para centrar el checkbox
            />
            <p>{selectedCotizaciones.length} seleccionados</p>
            <button className="boton-accion">Marcar como enviado</button>
            <div className="bulk-actions-right">
              <i
                className="bi bi-three-dots-vertical"
                onClick={toggleBulkActionsOptions}
              ></i>
              {showBulkActionsOptions && (
                <div className="dropdown-options">
                  <p onClick={exportarPDF}>Exportar como PDF</p>
                  <p onClick={imprimir}>Imprimir</p>
                  <p onClick={eliminar}>Eliminar</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="encabezado">
        <div className="categoria-filtro" onClick={toggleDropdown}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginLeft: "10px" }}>Todas las cotizaciones</p>
            <i
              className="bi bi-chevron-down"
              style={{ color: "#007bff", fontWeight: "bold" }}
            ></i>
          </div>
          {showDropdown && (
            <div className="dropdown-menu">
              <p><i className="bi bi-envelope-check"></i>Cotizaciones enviadas</p>
              <p><i className="bi bi-clock"></i>Cotizaciones pendientes</p>
            </div>
          )}
        </div>
        <div className="acciones-encabezado" style={{ position: "relative" }}>
          <button className="boton-accion" onClick={openFormulario}>
            + Nuevo
          </button>
          <i className="bi bi-three-dots-vertical" onClick={toggleOptions}></i>
          {showOptions && (
            <div className="dropdown-options">
              <p><i className="bi bi-arrow-down-up" style={{marginRight: "10px"}}></i>Ordenar</p>
              <p><i className="bi bi-box-arrow-in-down" style={{marginRight: "10px"}}></i>Importar</p>
              <p><i className="bi bi-box-arrow-up" style={{marginRight: "10px"}}></i>Exportar</p>
            </div>
          )}
        </div>
      </div>

      {paginatedCotizaciones.length > 0 ? (
        paginatedCotizaciones.map((cotizacion) => (
          <div
    key={cotizacion.id}
    className={`mensaje-cotizacion ${
      selectedCotizacionId === cotizacion.id ? "mensaje-seleccionado" : ""
    }`}
    onClick={() => {
      onRowClick(cotizacion.id);
    }}
    tabindex="0"
  >
            <div className="info-container">
              <input
                type="checkbox"
                onChange={() => handleSelectCotizacion(cotizacion.id)}
                checked={selectedCotizaciones.includes(cotizacion.id)}
              />
              <div className="info-column">
                <div className="cliente-importe">
                  <span className="cliente-nombre">
                    {cotizacion.nombreCliente}
                  </span>
                  <span className="importe">
                    {cotizacion.divisa}{" "}
                    {parseFloat(cotizacion?.total)
                      ?.toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </span>
                </div>
                <div className="fecha-referencia">
                  <p>
                    No. Cotización:{" "}
                    {cotizacion.numeroCotizacion.toString().padStart(4, "0")}
                  </p>
                  <span className="punto">•</span>
                  <p>
                    {formatDate(new Date(cotizacion.fechaCotizacion))}
                  </p>
                </div>
                <div
                  className={`status2 ${
                    cotizacion.estado === "Pendiente"
                      ? "pendiente2"
                      : cotizacion.estado === "En Proceso"
                      ? "en-proceso2"
                      : "pagada2"
                  }`}
                >
                  {cotizacion.estado}
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p style={{ position: "relative", right: "32%" }}>
          No existe ninguna cotización que coincida con esta búsqueda.
        </p>
      )}

      <div className="paginador">
        <div className="paginador-opciones">
          <div className="paginador-config">
            <div className="paginador-select-wrapper">
              <i
                className="bi bi-gear-fill"
                style={{ marginRight: "10px" }}
              ></i>
              <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option} por página
                  </option>
                ))}
              </select>
            </div>
            <button
              onClick={() => handlePageChange("prev")}
              disabled={currentPage === 1}
            >
              {"<"}
            </button>
            <span>
              {(currentPage - 1) * itemsPerPage + 1} -{" "}
              {Math.min(
                currentPage * itemsPerPage,
                filteredCotizaciones.length
              )}
            </span>
            <button
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages}
            >
              {">"}
            </button>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Nueva Cotización"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            paddingLeft: 0,
            padding: 0,
            fontFamily: "Roboto, sans-serif",
            "::WebkitScrollbar": {
              width: "0px",
              background: "transparent",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          },
        }}
      >
        <button onClick={closeModal} className="cerrar-button">
          <i className="bi bi-x-lg"></i>
        </button>

        <CotizacionForm
          clientes={clientes}
          guardarCotizacion={guardarCotizacion}
          cotizacion={cotizacion}
          onFormDirtyChange={(dirty) => setIsFormDirty(dirty)}
        />
      </Modal>
    </div>
  );
};

export default BandejaCotizaciones;