import React, { useState, useEffect, useMemo } from "react";
import {
  pdf, // Importamos pdf para generar el blob
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import EditarCotizacionForm from "./EditarCotizacionForm";
import { doc, getDoc } from "firebase/firestore"; // Importar funciones de Firestore
import { db } from "../firebaseConfig";
import InterRegular from "../fonts/Inter_18pt-Medium.ttf"; // Ruta de la fuente
import InterBold from "../fonts/Inter_28pt-ExtraBold.ttf"; // Ruta de la fuente

Font.register({
  family: "Inter",
  fonts: [
    { src: InterRegular, fontWeight: "normal" },
    { src: InterBold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  pdfContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 1000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pdfViewer: {
    width: "80%",
    height: "90%",
    border: "1px solid #ccc",
    borderRadius: 5,
    overflow: "hidden",
  },
  page: {
    padding: 30,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "20px",
  },
  logoContainer: {
    width: "50%",
    marginTop: "5px",
  },
  logo: {
    width: "150px",
    height: "auto",
  },
  headerInfo: {
    width: "50%",
    textAlign: "right",
  },
  companyName: {
    fontSize: "10px",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  companyDetails: {
    fontSize: "10px",
    fontFamily: "Inter",
    marginTop: "2px",
  },
  titleSection: {
    textAlign: "center",
    marginBottom: "20px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    color: "#1976d2",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  clientInfo: {
    width: "50%",
    fontSize: "10px",
  },
  infoTitle: {
    fontSize: "10px",
    fontFamily: "Inter",
    marginBottom: "5px",
  },
  infoText: {
    fontSize: "10px",
    fontFamily: "Inter",
    marginBottom: "3px",
  },
  infoTextColor: {
    fontSize: "10px",
    fontFamily: "Inter",
    marginBottom: "3px",
    color: "#1976d2",
    fontWeight: "bold",
  },
  infoBlessed: {
    fontSize: "11px",
    marginTop: "10px",
    fontFamily: "Inter",
    textAlign: "left",
    marginBottom: "10px",
  },
  cotizacionTable: {
    width: "50%",
    textAlign: "right",
    marginTop: "-10px",
  },
  table: {
    marginTop: "10px",
    fontFamily: "Inter",
    width: "100%",
    borderWidth: 1,
    borderColor: "#fff",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#fff",
    backgroundColor: "#F9F9F9",
  },
  tableRowHeader: {
    flexDirection: "row",
    backgroundColor: "#6c6c6c",
    borderBottomWidth: 1,
    borderColor: "#fff",
    color: "#fff",
    fontSize: "10px",
  },
  tableCell: {
    padding: 8,
    textAlign: "left", // Cambiar a 'center' si deseas centrar el contenido
    borderRight: "1px solid #fff", // Borde derecho sutil para las celdas
    fontSize: "11px",
    lineHeight: "1.5",
  },
  closeButton: {
    color: "#fff",
    top: "15px",
    fontSize: "20px",
  },
  tableSmall: {
    marginTop: "10px",
  },
  tableRowSmall: {
    display: "flex",
    left: "55%",
    flexDirection: "row",
    marginBottom: "3px", // Margen reducido para menor altura de fila
  },
  tableLabelSmall: {
    fontSize: "9px", // Tamaño de fuente más pequeño
    fontWeight: "bold",
    width: "40%",
    padding: "6px",
    color: "#fff",
    backgroundColor: "#6c6c6c",
    textAlign: "left",
  },
  tableValueSmall: {
    fontSize: "9px", // Tamaño de fuente más pequeño
    width: "40%",
    textAlign: "left",
    margin: "5px",
  },
  footerContainer: {
    marginTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-start",
    minHeight: 150, // Asegura que tenga espacio al final
  },
  footerLeft: {
    width: "45%",
    textAlign: "justify",
    paddingRight: 20,
  },
  footerRight: {
    width: "40%",
    textAlign: "right",
    minHeight: 100, // Añade un mínimo para evitar que se salga de la página
  },
  footerRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  footerRowGray: {
    backgroundColor: "#F9F9F9",
    padding: 8,
  },
  footerRowBlue: {
    backgroundColor: "#1565c0",
    padding: 8,
  },
  footerLabel: {
    fontSize: 11,
    textAlign: "right",
  },
  footerValue: {
    fontSize: 11,
    textAlign: "right",
  },
  footerLabelBlue: {
    color: "#fff",
    fontWeight: "bold",
  },
  footerValueBlue: {
    color: "#fff",
    fontWeight: "bold",
    letterSpacing: "0.5px",
    margin: 0
  },
  footerLabelContainer: {
    minWidth: 90,
    paddingRight: 10,
    flex: 1, // Añadido para ocupar todo el espacio disponible
    justifyContent: "flex-end", // Alinea el contenido al final
    alignItems: "flex-end", // Alinea el contenido horizontalmente al final
  },
  footerValueContainer: {
    paddingLeft: 0,
    paddingRight: 5,
    margin: 0,
    flex: 1, // Asegura que ocupe el mismo espacio que el label
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  condicionesTitle: {
    fontSize: "10px",
    marginBottom: 15,
    marginTop: 10,
    fontFamily: "Inter",
  },
  termsContainer: {
    gap: 10,
    textAlign: "justify",
    fontFamily: "Inter",
  },
  numeroDePagina: {
    color: "#999999",
    fontSize: 12,
  },
  condicionesContainer: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  condicionesTitulo: {
    fontSize: 12,
    fontWeight: 600,
    color: "red",
    marginBottom: 5,
    fontFamily: "Inter",
  },
  condicionesTexto: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Inter",
    textAlign: "justify",
  },
  condicionesTexto2: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Inter",
    textAlign: "justify",
  },
  espaciado: {
    marginBottom: 20,
  },
  // Contenedor de la sección de cancelaciones
  cancelacionesContainer: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  cancelacionesTitulo: {
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: "bold",
    color: "red",
    marginBottom: 5,
    marginTop: 15,
  },
  cancelacionesTexto: {
    fontSize: 11,
    marginBottom: 5,
    textAlign: "justify",
    fontFamily: "Inter",
  },
  // Estilo para la sección de garantía
  garantiaContainer: {
    marginBottom: 10,
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  garantiaTexto: {
    fontSize: 11,
    textAlign: "justify",
    marginBottom: 3,
    fontFamily: "Inter",
  },
  // Contenedor de los datos bancarios
  bancariosContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch", // Mantiene ambas secciones alineadas verticalmente
    padding: 10,
    borderWidth: 1,
    margin: 0,
    borderColor: "#000",
    height: 120, // Ajustamos la altura para que el contenedor mantenga el tamaño fijo
  },
  datosBancariosIzquierda: {
    width: "48%", // Ajusta el ancho para dejar espacio para la línea divisoria
    gap: 5,
  },
  datosBancariosDerecha: {
    width: "48%", // Ajusta el ancho para dejar espacio para la línea divisoria
    textAlign: "left",
  },
  lineaDivisoria: {
    width: 1, // Ancho de la línea divisoria
    backgroundColor: "#000", // Color de la línea
    height: "119px", // Fuerza a la línea a ocupar toda la altura del contenedo
    top: -10,
  },
  datosBancariosTitulo: {
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: "Inter",
    marginBottom: 5,
  },
  banco: {
    fontSize: 11,
    fontFamily: "Inter",
    marginBottom: 3,
  },
  moneda: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Inter",
  },
  beneficiario: {
    fontSize: 11,
    fontFamily: "Inter",
    marginBottom: 3,
  },
  cuenta: {
    fontSize: 11,
    marginBottom: 3,
    fontFamily: "Inter",
  },
  clabe: {
    fontSize: 11,
    fontFamily: "Inter",
    marginBottom: 3,
  },
  referencias: {
    fontSize: 11,
    color: "red",
    marginBottom: 5,
    fontFamily: "Inter",
  },
  envioComprobante: {
    fontSize: 11,
    color: "red",
    fontFamily: "Inter",
    marginBottom: 3,
  },
  correos: {
    fontSize: 11,
    marginBottom: 2,
    fontFamily: "Inter",
  },
});

const ResumenCotizacion = ({
  cotizacion,
  isOpen,
  onClose,
  clientes,
  setCotizaciones,
  cotizaciones,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showSummary, setShowSummary] = useState(true);
  const [pdfVisible, setPdfVisible] = useState(false);
  const [cliente, setCliente] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (cotizacion?.cliente) {
      const fetchCliente = async () => {
        try {
          const clienteDoc = await getDoc(
            doc(db, "clientes", cotizacion.cliente)
          );
          if (clienteDoc.exists()) {
            setCliente(clienteDoc.data());
          } else {
            console.error("Cliente no encontrado");
          }
        } catch (error) {
          console.error("Error al obtener el cliente:", error);
        }
      };

      fetchCliente();
    }
  }, [cotizacion?.cliente]);

  useEffect(() => {
    if (!isOpen && onClose) {
      onClose();
    }
  }, [isOpen, onClose]);

  // Memoriza el documento PDF
  const memoizedPdfDocument = useMemo(() => {
    if (!cotizacion || !cliente || !cotizacion.productosSeleccionados.length) {
      return null;
    }

    // Definir variables dentro de useMemo
    const productosPorPagina = 15; // Ajusta este número según sea necesario
    const paginasDeProductos = [];

    for (
      let i = 0;
      i < cotizacion.productosSeleccionados.length;
      i += productosPorPagina
    ) {
      paginasDeProductos.push(
        cotizacion.productosSeleccionados.slice(i, i + productosPorPagina)
      );
    }

    // Subtotal
    const subtotal = cotizacion.productosSeleccionados.reduce(
      (acc, producto) => acc + producto.tarifa * producto.cantidad,
      0
    );

    // Descuento Aplicado
    const descuentoPorcentaje = cotizacion.descuento || 0;
    const descuentoAplicado = subtotal * (descuentoPorcentaje / 100);

    // Subtotal con Descuento (Restante)
    const subtotalConDescuento = subtotal - descuentoAplicado;

    // IVA Total
    const ivaRate = 0.16; // Tasa de IVA
    const ivaTotal = subtotalConDescuento * ivaRate;

    // Total final
    const total = subtotalConDescuento + ivaTotal;

    function formatDate(dateString) {
      const date = dateString ? new Date(dateString) : new Date();
      const day = date.getDate().toString().padStart(2, "0");

      const months = [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ];
      const month = months[date.getMonth()];

      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    }

    return (
      <Document>
        {paginasDeProductos.map((productosPagina, pageIndex) => (
          <Page
            key={pageIndex}
            size="A4"
            orientation="landscape"
            style={styles.page}
          >
            {/* Header y detalles de la cotización solo en la primera página */}
            {pageIndex === 0 && (
              <>
                {/* Header */}
                <View style={styles.header}>
                  <View style={styles.logoContainer}>
                    <Image src="/img/logo-iciamex.png" style={styles.logo} />
                  </View>
                  <View style={styles.headerInfo}>
                    <Text style={styles.companyName}>
                      UNIFICACION PARA LA AUTOMATIZACION SA DE CV
                    </Text>
                    <Text style={styles.companyDetails}>
                      Lic. Ramiro Hernández 448, Las Pintitas
                    </Text>
                    <Text style={styles.companyDetails}>
                      El Salto, Jalisco, Mexico, C.P. 45693
                    </Text>
                    <Text style={styles.companyDetails}>(33) 1602 2358</Text>
                    <Text style={styles.companyDetails}>UAU151222IT1</Text>
                  </View>
                </View>

                {/* Title */}
                <View style={styles.titleSection}>
                  <Text style={styles.title}>COTIZACIÓN</Text>
                </View>

                {/* Information Sections */}
                <View style={styles.infoContainer}>
                  {/* Left Side: Cliente Information */}
                  <View style={styles.clientInfo}>
                    <Text style={styles.infoTitle}>Cliente:</Text>
                    <Text style={styles.infoTextColor}>
                      {cotizacion.nombreCliente}
                    </Text>
                    <Text style={styles.infoText}>
                      {cliente.calle} {cliente.numeroExt}, {cliente.colonia}
                    </Text>
                    <Text style={styles.infoText}>
                      {cliente.ciudad}, {cliente.estado}.
                    </Text>
                    <Text style={styles.infoText}>
                      C.P. {cliente.codigoPostal}
                    </Text>
                  </View>

                  {/* Right Side: Cotización Table */}
                  <View style={styles.cotizacionTable}>
                    <View style={styles.tableSmall}>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>Cotización #</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.numeroCotizacion
                            ?.toString()
                            .padStart(4, "0")}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>Fecha</Text>
                        <Text style={styles.tableValueSmall}>
                          {formatDate(cotizacion.fechaCotizacion)}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>Vencimiento</Text>
                        <Text style={styles.tableValueSmall}>
                          {formatDate(cotizacion.fechaVencimiento)}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>Vendedor</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.vendedorNombre}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>Atención</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.atencion}
                        </Text>
                      </View>
                      <View style={styles.tableRowSmall}>
                        <Text style={styles.tableLabelSmall}>Área</Text>
                        <Text style={styles.tableValueSmall}>
                          {cotizacion.area}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>

                <Text style={styles.infoBlessed}>
                  Gracias por darnos la oportunidad de hacerle una propuesta de
                  nuestros equipos y/o servicios. Estamos seguros que quedará
                  satisfecho con los beneficios que ofrecemos
                </Text>
              </>
            )}

            {/* Product Table */}
            <View style={styles.table}>
              {/* Encabezados de la tabla */}
              <View style={styles.tableRowHeader}>
                <Text
                  style={[styles.tableCell, styles.tableHeader, { flex: 0.1 }]}
                >
                  #
                </Text>
                <Text
                  style={[styles.tableCell, styles.tableHeader, { flex: 5 }]}
                >
                  Artículo & Descripción
                </Text>
                <Text
                  style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}
                >
                  Modelo
                </Text>
                <Text
                  style={[styles.tableCell, styles.tableHeader, { flex: 0.5 }]}
                >
                  Cant.
                </Text>
                <Text
                  style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}
                >
                  Precio Unitario
                </Text>
                <Text
                  style={[styles.tableCell, styles.tableHeader, { flex: 1 }]}
                >
                  Importe
                </Text>
              </View>

              {/* Filas de Productos */}
              {productosPagina.map((producto, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={[styles.tableCell, { flex: 0.1 }]}>
                    {index + 1 + pageIndex * productosPorPagina}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 5 }]}>
                    {producto.nombre}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 1 }]}>
                    {producto.numeroDeParte}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 0.5 }]}>
                    {producto.cantidad}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 1 }]}>
                    {parseFloat(producto.tarifa)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                  <Text style={[styles.tableCell, { flex: 1 }]}>
                    {parseFloat(producto.importe)
                      .toFixed(2)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                  </Text>
                </View>
              ))}

              {/* Si es la última página de productos, mostramos los totales */}
              {pageIndex === paginasDeProductos.length - 1 && (
                <View style={styles.footerContainer} wrap={false}>
                  {/* Condiciones de Pago - Izquierda */}
                  <View style={styles.footerLeft}>
                    <Text style={styles.condicionesTitle}>
                      Condiciones de Pago:
                    </Text>
                    <Text style={{ fontSize: 10, marginBottom: 4 }}>
                      {cotizacion.notas}
                    </Text>
                  </View>

                  {/* Sección de Totales - Derecha */}
                  <View style={styles.footerRight}>
                    <View style={[styles.footerRow, styles.footerRowGray]}>
                      <View style={styles.footerLabelContainer}>
                        <Text style={[styles.footerLabel]}>Subtotal</Text>
                      </View>
                      <View style={styles.footerValueContainer}>
                        <Text style={[styles.footerValue]}>
                          $
                          {subtotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </View>
                    </View>

                    {descuentoAplicado > 0 && (
                      <>
                        <View style={[styles.footerRow, styles.footerRowGray]}>
                          <View style={styles.footerLabelContainer}>
                            <Text style={[styles.footerLabel]}>
                              Descuento ({descuentoPorcentaje}%)
                            </Text>
                          </View>
                          <View style={styles.footerValueContainer}>
                            <Text style={[styles.footerValue]}>
                              -$
                              {descuentoAplicado
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Text>
                          </View>
                        </View>
                        <View style={[styles.footerRow, styles.footerRowGray]}>
                          <View style={styles.footerLabelContainer}>
                            <Text style={[styles.footerLabel]}>Restante</Text>
                          </View>
                          <View style={styles.footerValueContainer}>
                            <Text style={[styles.footerValue]}>
                              $
                              {subtotalConDescuento
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </Text>
                          </View>
                        </View>
                      </>
                    )}

                    <View style={[styles.footerRow, styles.footerRowGray]}>
                      <View style={styles.footerLabelContainer}>
                        <Text style={[styles.footerLabel]}>IVA</Text>
                      </View>
                      <View style={styles.footerValueContainer}>
                        <Text style={[styles.footerValue]}>
                          $
                          {ivaTotal
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Text>
                      </View>
                    </View>

                    <View style={[styles.footerRow, styles.footerRowBlue]}>
                      <View style={styles.footerLabelContainer}>
                        <Text
                          style={[styles.footerLabel, styles.footerLabelBlue]}
                        >
                          Total
                        </Text>
                      </View>
                      <View style={styles.footerValueContainer}>
                        <Text
                          style={[styles.footerValue, styles.footerValueBlue]}
                        >
                          $
                          {total
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}{cotizacion.divisa}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              )}
            </View>

            {/* Footer: Page number */}
            <View
              fixed
              style={{
                position: "absolute",
                bottom: 20,
                left: 0,
                right: 10,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center", // Para asegurarte de que estén verticalmente alineados
              }}
            >
              {/* Texto alineado a la izquierda */}
              <Text
                style={{
                  fontSize: 10,
                  textAlign: "left",
                  marginLeft: 10,
                  color: "#999999",
                }}
              >
                UNIFICACION PARA LA AUTOMATIZACION SA DE CV
              </Text>

              {/* Número de página alineado a la derecha */}
              <Text
                style={styles.numeroDePagina}
                render={({ pageNumber }) => `${pageNumber}`}
              />
            </View>
          </Page>
        ))}

        {/* Página de Términos y Condiciones */}
        <Page size="A4" orientation="landscape" style={styles.page}>
          <View style={styles.termsContainer}>
            <Text style={{ fontSize: "13px" }}>Términos y condiciones</Text>
            <Text style={{ fontSize: "11px" }}>
              Tiempo de entrega: 1 Semana después de confirmar con OC.
            </Text>
            <Text style={{ fontSize: "11px" }}>
              Para las cotizaciones elaboradas en dólares, el tipo de cambio a
              tomar será el especificado en el Diario Oficial de la Federación
              el día de la facturación y/o pago.
            </Text>
          </View>

          {/* Footer: Page number */}
          <View
            fixed
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              right: 10,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center", // Para asegurarte de que estén verticalmente alineados
            }}
          >
            {/* Texto alineado a la izquierda */}
            <Text
              style={{
                fontSize: 10,
                textAlign: "left",
                marginLeft: 10,
                color: "#999999",
              }}
            >
              UNIFICACION PARA LA AUTOMATIZACION SA DE CV
            </Text>

            {/* Número de página alineado a la derecha */}
            <Text
              style={styles.numeroDePagina}
              render={({ pageNumber }) => `${pageNumber}`}
            />
          </View>
        </Page>

        <Page size="A4" orientation="landscape" style={styles.page}>
          {/* Sección de Condiciones Importantes */}
          <View>
            <Text style={styles.condicionesTitulo}>
              CONDICIONES IMPORTANTES.
            </Text>
            <Text style={[styles.condicionesTexto, styles.espaciado]}>
              Las cotizaciones, incluyendo imágenes y precios, son de carácter
              informativo, y podrán variar según el producto y la fecha del
              pedido. En el caso de fabricación de equipos, el diseño final será
              enviado una vez que se haya confirmado orden de compra o anticipo.
            </Text>
            <Text style={styles.condicionesTexto}>
              Favor de confirmar existencias antes de realizar pedido.
            </Text>
            <Text style={styles.condicionesTexto}>
              Precios en Moneda Nacional (Pesos Mexicanos). A todos los precios
              enlistados hay que agregarles el IVA. Precios sujetos a cambio sin
              previo aviso.
            </Text>
            <Text style={styles.condicionesTexto}>
              En algunos pedidos se requiere 50% de anticipo para empezar la
              fabricación del proyecto.
            </Text>
          </View>

          {/* Sección de Cancelaciones */}
          <View>
            <Text style={styles.cancelacionesTitulo}>
              TODA CANCELACIÓN O DEVOLUCIÓN GENERA UN CARGO.
            </Text>
            <Text style={[styles.cancelacionesTexto, styles.espaciado]}>
              Se aplicará un 50% del valor del producto a las cancelaciones
              solicitadas, y según la etapa del proyecto, pero estará
              condicionado a la aprobación de nuestra área de Ingeniería y
              Mantenimiento. Este valor es debido a que los equipos son
              fabricados especialmente y de acuerdo a las especificaciones del
              cliente.
            </Text>
            <Text style={styles.cancelacionesTexto}>
              <Text style={{ color: "red" }}>IMPORTANTE:</Text> Todos los
              pedidos que requieran del servicio de paquetería se enviarán a la
              dirección de facturación si el cliente no especifica por escrito
              la dirección del envío. El tiempo de entrega dependerá de la
              paquetería elegida por el cliente.
            </Text>
            <Text style={[styles.cancelacionesTexto, styles.espaciado]}>
              La capacitación o asesoría fuera de nuestras instalaciones
              generará un costo adicional, así como las visitas para cualquier
              servicio o mantenimiento solicitado, agregándose al mismo un cargo
              por transporte, hospedaje y viáticos, y estarán sujetas a
              programación.
            </Text>
          </View>

          {/* Sección de Garantía */}
          <View style={styles.garantiaContainer}>
            <Text style={styles.garantiaTexto}>
              GARANTÍA. Los equipos cuentan con 1 año de garantía, ya sea por
              defectos de manufactura o de los materiales, excepto en las partes
              eléctricas y en aquellos que por mal uso se deterioren. Todas las
              garantías se validarán en nuestro domicilio a excepción de
              aquellas que se soliciten en otra ubicación, siempre y cuando haya
              sido cubierto al 100% el costo que este genere. Nos reservamos el
              derecho de aplicar la garantía si el equipo ha sido utilizado de
              forma inadecuada o detecta intentos de reparación por personas no
              autorizadas.
            </Text>
          </View>

          <View style={styles.bancariosContainer}>
            {/* Columna Izquierda */}
            <View style={styles.datosBancariosIzquierda}>
              <Text style={styles.datosBancariosTitulo}>DATOS BANCARIOS</Text>
              <Text style={styles.banco}>Banco: Banamex</Text>
              <Text style={styles.moneda}>Depósitos en Moneda Nacional</Text>
              <Text style={styles.beneficiario}>
                Beneficiario: UNIFICACION PARA LA AUTOMATIZACION SA DE CV
              </Text>
              <Text style={styles.cuenta}>Cta: 04856983</Text>
              <Text style={styles.clabe}>Clabe: 002320701048569836</Text>
            </View>

            {/* Línea Divisoria */}
            <View style={styles.lineaDivisoria} />

            {/* Columna Derecha */}
            <View style={styles.datosBancariosDerecha}>
              <Text style={[styles.referencias, styles.espaciado]}>
                Referenciar el depósito con el nombre del cliente, número de
                cotización, pedido, factura, o cualquier dato que nos ayude a
                identificarlo.
              </Text>
              <Text style={[styles.envioComprobante, styles.espaciado]}>
                Favor de enviar su comprobante de pago a los siguientes correos:
              </Text>
              <Text style={styles.correos}>contacto@iciamex.com</Text>
              <Text style={styles.correos}>contabilidad@iciamex.com</Text>
            </View>
          </View>

          {/* Footer: Número de página y texto alineado */}
          <View
            fixed
            style={{
              position: "absolute",
              bottom: 20,
              left: 0,
              right: 10,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                textAlign: "left",
                marginLeft: 10,
                color: "#999999",
              }}
            >
              UNIFICACION PARA LA AUTOMATIZACION SA DE CV
            </Text>
            <Text
              style={styles.numeroDePagina}
              render={({ pageNumber }) => `${pageNumber}`}
            />
          </View>
        </Page>
      </Document>
    );
  }, [cotizacion, cliente]);

  useEffect(() => {
    let isMounted = true;
    let url;

    const generateBlobUrl = async () => {
      if (!memoizedPdfDocument) {
        setPdfBlobUrl(null);
        setLoading(false);
        return;
      }
      setLoading(true);
      const blob = await pdf(memoizedPdfDocument).toBlob();
      if (isMounted) {
        url = URL.createObjectURL(blob);
        setPdfBlobUrl(url);
        setLoading(false);
      }
    };

    generateBlobUrl();

    // Función de limpieza
    return () => {
      isMounted = false;
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [memoizedPdfDocument]);

  const handlePrint = () => {
    setPdfVisible(true);
  };

  const handleClosePDF = () => {
    setPdfVisible(false);
  };

  if (pdfVisible) {
    return (
      <div style={styles.pdfContainer}>
        <button
          className="cerrar-button"
          style={styles.closeButton}
          onClick={handleClosePDF}
        >
          <i className="bi bi-x-lg"></i>
        </button>
        <PDFViewer className="pdf-viewer" style={styles.pdfViewer}>
          {memoizedPdfDocument}
        </PDFViewer>
      </div>
    );
  }

  const handleEdit = () => {
    setEditMode(true);
    setShowSummary(false);
  };

  const closeModal = () => {
    setEditMode(false);
    setShowSummary(true); // Mostramos el resumen al cerrar el formulario de edición
    onClose();
  };

  if (editMode) {
    return (
      <EditarCotizacionForm
        cotizacion={cotizacion}
        clientes={clientes}
        productos={cotizacion && cotizacion.productosSeleccionados}
        setCotizaciones={setCotizaciones}
        cotizaciones={cotizaciones}
        onClose={closeModal}
      />
    );
  }

  // Si el resumen debe mostrarse y la cotización y los productos están disponibles
  if (showSummary && cotizacion && cotizacion.productosSeleccionados) {
    return (
      <div className="resumen-cotizacion-container">
        <button className="cerrarBotonResumen" onClick={onClose}>
          <i className="bi bi-x-lg"></i>
        </button>
        <div className="resumen-cotizacion-actions">
          <div
            className="dropdown"
            onMouseEnter={() => setShowOptions(true)}
            onMouseLeave={() => setShowOptions(false)}
          >
            <button className="dropbtn">
              <i className="bi bi-printer iconResumen"></i> Imprimir / PDF
            </button>
            {showOptions && (
              <div className="dropdown-content">
                <button onClick={handlePrint}>
                  <i className="bi bi-file-earmark-text" style={{ marginRight: "10px" }}></i>
                  Imprimir
                </button>
                <a
                  href={pdfBlobUrl}
                  download="previa_cotizacion.pdf"
                  style={{ textDecoration: "none" }}
                >
                  <button>
                    <i className="bi bi-download" style={{ marginRight: "10px" }}></i>
                    Descargar PDF
                  </button>
                </a>
              </div>
            )}
          </div>
          <button>
            <i className="bi bi-envelope-fill iconResumen"></i> Correo
          </button>
          <button onClick={handleEdit}>
            <i className="bi bi-pencil-fill iconResumen"></i> Editar
          </button>
          <button>
            <i className="bi bi-receipt iconResumen"></i> Convertir en Factura
          </button>
        </div>
  
        <div style={{ height: "600px", width: "100%", marginTop: "-20px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {loading ? (
            <div className="loader2"></div> // Spinner visible mientras se carga
          ) : (
            pdfBlobUrl && (
              <iframe
                src={`${pdfBlobUrl}#toolbar=0`}
                style={{ width: "100%", height: "100%" }}
                frameBorder="0"
                title="Vista previa del PDF"
              />
            )
          )}
        </div>
      </div>
    );
  }
  

  return null;
};

export default ResumenCotizacion;