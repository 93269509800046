import React, { useState, useEffect, useRef } from "react";
import PreviaCotizacion from "./PreviaCotizacion";
import { useLocation } from "react-router-dom";
import ResumenCotizacion from "./ResumenCotizacion";
import BandejaCotizaciones from "./BandejaCotizaciones";
import Nav from "./Nav";
import CotizacionForm from "./CotizacionForm"; // Importa el componente CotizacionForm
import {
  collection,
  deleteDoc,
  getFirestore,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import Swal from "sweetalert2";
import Modal from "react-modal";
import moment from "moment";
import Spinner from "./Spinner";
import { Link } from "react-router-dom";

// Configura el localizador de fechas usando moment.js
moment.locale("es");

const styleForm = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    paddingLeft: 0,
    padding: 0,
    fontFamily: "Roboto, sans-serif",
    "::WebkitScrollbar": {
      width: "0px",
      background: "transparent",
    },
    /* Ocultar scrollbar en IE y Edge */
    msOverflowStyle: "none",
    /* Ocultar scrollbar en Firefox */
    scrollbarWidth: "none",
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "800px",
    width: "100%",
    height: "550px",
    maxHeight: "90vh",
    overflow: "auto",
    fontFamily: "Roboto, sans-serif", // Aplica la fuente Roboto
  },
  label: {
    display: "block",
    marginBottom: "10px",
  },
  input: {
    width: "100%",
    padding: "8px",
    marginBottom: "20px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
  },
  button: {
    width: "50%", // Centra el botón de agregar
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    margin: "0 auto",
    fontWeight: "700",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
};

function TablaCotizaciones({
  cotizaciones,
  clientes,
  setCotizaciones,
  guardarCotizacion,
  modoEdicion,
  cotizacion,
  numeroCotizacion,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cotizacionSeleccionada, setCotizacionSeleccionada] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingCotizaciones, setLoadingCotizaciones] = useState(false);
  const [selectedCotizaciones, setSelectedCotizaciones] = useState([]);
  const [ordenamiento, setOrdenamiento] = useState({
    campo: "fechaCotizacion",
    ascendente: true,
  });
  /*const [showOptions, setShowOptions] = useState(false);*/
  const [selectedCotizacionId, setSelectedCotizacionId] = useState(null);
  const [resumenVisible, setResumenVisible] = useState(false);
  const [showBandeja, setShowBandeja] = useState(false);
  const [mostrarFormulario, setMostrarFormulario] = useState(false);
  const [showNuevoButton, setShowNuevoButton] = useState(true);
  const setCotizacionesRef = useRef(setCotizaciones);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [formDirty, setFormDirty] = useState(false); // Añade esto
  const [proximosEventos, setProximosEventos] = useState([]);
  const location = useLocation();
  const [estadosCeldas, setEstadosCeldas] = useState({});

  const handleFormDirtyChange = (dirty) => {
    setFormDirty(dirty);
  };

  // Al cargar la página, obtenemos las cotizaciones y los estados de Firestore
  useEffect(() => {
    setLoadingCotizaciones(true);
    const firestore = getFirestore();
    const cotizacionesRef = collection(firestore, "cotizaciones");

    const unsubscribe = onSnapshot(cotizacionesRef, (snapshot) => {
      const updatedCotizaciones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Extraemos los estados de cada remisión desde Firestore
      const estados = {};
      updatedCotizaciones.forEach((cotizacion) => {
        estados[cotizacion.id] = cotizacion.estado || "Pendiente"; // Asignar 'Pendiente' si no hay estado
      });
      setEstadosCeldas(estados);

      setCotizacionesRef.current(updatedCotizaciones);
      setLoadingCotizaciones(false);
    });

    return () => unsubscribe();
  }, [setCotizaciones]);

  // Función para alternar entre los estados de cada celda seleccionada y guardarlo en Firestore
  const toggleEstado = async (cotizacionId) => {
    const newEstado =
      estadosCeldas[cotizacionId] === "Pendiente"
        ? "En Proceso"
        : estadosCeldas[cotizacionId] === "En Proceso"
        ? "Pagada"
        : "Pendiente";

    // Actualizamos el estado localmente
    setEstadosCeldas((prevEstados) => ({
      ...prevEstados,
      [cotizacionId]: newEstado,
    }));

    // Guardamos el nuevo estado en Firestore
    const firestore = getFirestore();
    const cotizacionDocRef = doc(firestore, "cotizaciones", cotizacionId);
    await updateDoc(cotizacionDocRef, { estado: newEstado });
  };

  useEffect(() => {
    if (location.state && location.state.openModal) {
      setMostrarFormulario(true);
    }
  }, [location]);

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar las cotizaciones que tienen fecha de vencimiento a partir de hoy y ordenarlas
          const proximas = cotizaciones
            .filter(
              (cotizacion) =>
                moment(cotizacion.fechaVencimiento) >= moment().startOf("day")
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar eventos que ocurren a partir de hoy y ordenarlos
        const proximos = eventos
          .filter((evento) => moment(evento.to) >= moment().startOf("day"))
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Filtrar fechas festivas que están a menos de una semana
        const fechasFestivasProximas = [];
        const fechasFestivasBase = [
          { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
          {
            title: "Día de la Constitución",
            month: "02",
            day: "05",
            color: "#de2e03",
          },
          {
            title: "Natalicio de Benito Juárez",
            month: "03",
            day: "21",
            color: "#de2e03",
          },
          {
            title: "Día del Trabajo",
            month: "05",
            day: "01",
            color: "#de2e03",
          },
          {
            title: "Independencia de México",
            month: "09",
            day: "16",
            color: "#de2e03",
          },
          {
            title: "Transición del Poder Ejecutivo",
            month: "10",
            day: "01",
            color: "#de2e03",
          },
          {
            title: "Revolución Mexicana",
            month: "11",
            day: "20",
            color: "#de2e03",
          },
          { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
        ];

        const today = moment().startOf("day");
        const oneWeekFromNow = moment().add(7, "days").startOf("day");

        fechasFestivasBase.forEach((festivo) => {
          const festivoDate = moment(
            `${today.year()}-${festivo.month}-${festivo.day}`,
            "YYYY-MM-DD"
          );
          if (festivoDate.isBetween(today, oneWeekFromNow, null, "[]")) {
            fechasFestivasProximas.push({
              title: festivo.title,
              start: festivoDate.toDate(),
              end: festivoDate.toDate(),
              allDay: true,
              resource: "festivo",
              style: { backgroundColor: festivo.color },
            });
          }
        });

        if (fechasFestivasProximas.length > 0) {
          // Notificar sobre fechas festivas próximas
          console.log(
            `Fechas festivas próximas: ${fechasFestivasProximas
              .map(
                (festivo) =>
                  `${festivo.title} el ${moment(festivo.start).format("LL")}`
              )
              .join(", ")}`
          );
        }
      });

      return () => unsubscribeEventos();
    };

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  const openFormulario = () => {
    setMostrarFormulario(true);
    setShowNuevoButton(false); // Oculta el botón + Nuevo al abrir el formulario
  };

  // Dentro de TablaCotizaciones.js
  const closeModal = () => {
    if (formDirty) {
      Swal.fire({
        title: "¿Seguro que quieres salir?",
        text: "Se perderán los cambios realizados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, salir",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          setMostrarFormulario(false);
          setShowNuevoButton(true); // Vuelve a mostrar el botón + Nuevo al cerrar el formulario
          setFormDirty(false); // Restablece formDirty
        }
      });
    } else {
      setMostrarFormulario(false);
      setShowNuevoButton(true);
    }
  };

  useEffect(() => {
    setCotizacionesRef.current = setCotizaciones;
  }, [setCotizaciones]);

  useEffect(() => {
    setLoadingCotizaciones(true);
    const firestore = getFirestore();
    const cotizacionesRef = collection(firestore, "cotizaciones");

    const unsubscribe = onSnapshot(cotizacionesRef, (snapshot) => {
      const updatedCotizaciones = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCotizacionesRef.current(updatedCotizaciones);
      setLoadingCotizaciones(false);
    });

    return () => unsubscribe();
  }, []);

  const handleDeleteSelected = async () => {
    try {
      const confirmed = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará las cotizaciones seleccionadas.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (confirmed.isConfirmed) {
        const firestore = getFirestore();
        const cotizacionesEliminadas = [];
        const cotizacionesRef = collection(firestore, "cotizaciones");

        for (const cotizacionId of selectedCotizaciones) {
          const cotizacionDocRef = doc(cotizacionesRef, cotizacionId);
          await deleteDoc(cotizacionDocRef);
          cotizacionesEliminadas.push(cotizacionId);
        }

        const cotizacionesRestantes = cotizaciones.filter(
          (cotizacion) => !cotizacionesEliminadas.includes(cotizacion.id)
        );
        setCotizaciones(cotizacionesRestantes);
        setSelectedCotizaciones([]);

        // Mostrar alerta de éxito
        await Swal.fire({
          title: "Cotización eliminada",
          text: "Cotización eliminada correctamente.",
          icon: "success",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Ok",
        });
      }
    } catch (error) {
      console.error("Error al eliminar cotizaciones:", error);
    }
  };

  const filterCotizaciones = () => {
    const cotizacionesFiltradas =
      cotizaciones &&
      cotizaciones.filter((cotizacion) => {
        const searchableFields = [
          cotizacion.fechaCotizacion,
          cotizacion.numeroCotizacion?.toString().padStart(4, "0"),
          cotizacion.asunto?.toLowerCase(),
          cotizacion.nombreCliente?.toLowerCase(),
          cotizacion.total?.toString(),
          cotizacion.referencia?.toString(),
        ];
        return searchableFields.some(
          (field) => field && field.includes(searchTerm.toLowerCase())
        );
      });
    return cotizacionesFiltradas || []; // Devuelve un array vacío si cotizacionesFiltradas es undefined
  };

  const cerrarModalPrevia = () => {
    setModalIsOpen(false);
    setCotizacionSeleccionada(null);
  };

  const handleRowClick = (cotizacionId) => {
    setSelectedCotizacionId(cotizacionId);
    setResumenVisible(true);
    setShowBandeja(true);
  };

  const handleSelectCotizacion = (cotizacionId) => {
    setSelectedCotizaciones((prevSelected) => {
      if (prevSelected.includes(cotizacionId)) {
        return prevSelected.filter((id) => id !== cotizacionId);
      } else {
        return [...prevSelected, cotizacionId];
      }
    });
  };

  const handleOrdenamientoChange = (campo) => {
    setOrdenamiento((prevOrdenamiento) => ({
      campo,
      ascendente:
        campo === prevOrdenamiento.campo ? !prevOrdenamiento.ascendente : true,
    }));
  };

  const ordenarCotizaciones = (cotizaciones, { campo, ascendente }) => {
    if (!cotizaciones || cotizaciones.length === 0) {
      return [];
    }

    const sortedCotizaciones = [...cotizaciones];
    switch (campo) {
      case "fechaCotizacion":
        sortedCotizaciones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.fechaCotizacion) - new Date(b.fechaCotizacion))
        );
        break;
      case "numeroCotizacion":
        sortedCotizaciones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) * (a.numeroCotizacion - b.numeroCotizacion)
        );
        break;
      case "referencia":
        sortedCotizaciones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            (new Date(a.referencia) - new Date(b.referencia))
        );
        break;

      case "nombreCliente":
        sortedCotizaciones.sort(
          (a, b) =>
            (ascendente ? 1 : -1) *
            a.nombreCliente.localeCompare(b.nombreCliente)
        );
        break;
      case "total":
        sortedCotizaciones.sort(
          (a, b) => (ascendente ? 1 : -1) * (a.total - b.total)
        );
        break;
      default:
        break;
    }
    return sortedCotizaciones;
  };

  // Función para seleccionar/deseleccionar todas las cotizaciones
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Si se marca el checkbox de "seleccionar todos", selecciona todas las cotizaciones
      const allCotizacionesIds = filterCotizaciones().map(
        (cotizacion) => cotizacion.id
      );
      setSelectedCotizaciones(allCotizacionesIds);
    } else {
      // Si se desmarca, vacía la lista de seleccionados
      setSelectedCotizaciones([]);
    }
  };

  function formatDate(dateString) {
    const date = dateString ? new Date(dateString) : new Date(); // Usa la fecha actual si dateString no está definida
    const day = date.getDate().toString().padStart(2, "0");

    const months = [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ];
    const month = months[date.getMonth()];

    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  }

  return (
    <>
      {!showBandeja && !resumenVisible && (
        <>
          <Nav
            handleSearch={setSearchTerm}
            proximasAVencer={proximasAVencer}
            proximosEventos={proximosEventos}
          />

          <div className="task-filters">
            <div className="task-filters-search">
              <i className="bi bi-search search-icon"></i>
              <input
                type="text"
                className="search-input2"
                placeholder="Buscar cotizaciones"
              />
            </div>
            <div className="task-filters-right">
              <div className="task-filter filter-control">
                <Link href="#" className="filter-link control-link">
                  <i className="bi bi-sliders2-vertical"></i> Filter
                </Link>
              </div>
              <div className="view-buttons-container">
                <button className="view-button grid-view">
                  <i className="bi bi-grid"></i>
                </button>
                <button className="view-button list-view">
                  <i className="bi bi-list"></i>
                </button>
              </div>
              <div>
                {showNuevoButton && (
                  <button className="new-task-button" onClick={openFormulario}>
                    + Nuevo
                  </button>
                )}
              </div>

              <div style={{ marginLeft: "-10px" }}>
                {selectedCotizaciones.length > 0 && (
                  <div className="delete-button-container">
                    <button
                      type="button"
                      className="delete-button"
                      onClick={handleDeleteSelected}
                    >
                      Eliminar
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}

      <div className="cotizaciones-table">
        {showBandeja && (
          <BandejaCotizaciones
            cotizaciones={cotizaciones}
            setCotizaciones={setCotizaciones}
            onRowClick={handleRowClick}
            clientes={clientes}
            guardarCotizacion={guardarCotizacion}
            cotizacion={cotizacion}
            selectedCotizacionId={selectedCotizacionId}
          />
        )}
        {!showBandeja && (
          <div>
            {loadingCotizaciones ? (
              <Spinner />
            ) : (
              <>
                {filterCotizaciones().length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <input
                            type="checkbox"
                            onChange={handleSelectAll} // Controla la selección/desselección de todos los checkboxes
                            className="checkbox-select-all check"
                            checked={
                              selectedCotizaciones.length ===
                              filterCotizaciones().length
                            } // Checkbox marcado si todas están seleccionadas
                          />
                        </th>
                        <th
                          className="fecha"
                          onClick={() =>
                            handleOrdenamientoChange("fechaCotizacion")
                          }
                        >
                          Fecha
                        </th>
                        <th
                          className="no"
                          onClick={() =>
                            handleOrdenamientoChange("numeroCotizacion")
                          }
                        >
                          No.
                        </th>
                        <th
                          className="referencia"
                          onClick={() => handleOrdenamientoChange("referencia")}
                        >
                          Referencia
                        </th>
                        {/*   <th onClick={() => handleOrdenamientoChange("asunto")}>
                          Asunto
                        </th> */}
                        <th
                          className="cliente"
                          onClick={() =>
                            handleOrdenamientoChange("nombreCliente")
                          }
                        >
                          Cliente
                        </th>
                        <th
                          className="estado"
                          onClick={() => handleOrdenamientoChange("estado")}
                        >
                          Estado
                        </th>
                        <th
                          className="importe"
                          onClick={() => handleOrdenamientoChange("total")}
                        >
                          Importe
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {ordenarCotizaciones(
                        filterCotizaciones(),
                        ordenamiento
                      ).map((cotizacion, index) => (
                        <tr key={index}>
                          <td className="check">
                            <input
                              type="checkbox"
                              checked={selectedCotizaciones.includes(
                                cotizacion.id
                              )}
                              onChange={() =>
                                handleSelectCotizacion(cotizacion.id)
                              }
                              className="checkbox"
                            />
                          </td>
                          <td onClick={() => handleRowClick(cotizacion.id)}>
                            {formatDate(cotizacion.fechaCotizacion)}
                          </td>
                          <td onClick={() => handleRowClick(cotizacion.id)}>
                            {cotizacion.numeroCotizacion
                              ?.toString()
                              .padStart(4, "0")}
                          </td>
                          <td onClick={() => handleRowClick(cotizacion.id)}>
                            {cotizacion.referencia}
                          </td>
                          {/*   <td>{cotizacion.asunto}</td> */}
                          <td onClick={() => handleRowClick(cotizacion.id)}>
                            {cotizacion.nombreCliente}
                          </td>
                          <td>
                            <div
                              className={`status2 ${
                                estadosCeldas[cotizacion.id] === "Pendiente"
                                  ? "pendiente2"
                                  : estadosCeldas[cotizacion.id] ===
                                    "En Proceso"
                                  ? "en-proceso2"
                                  : "pagada2"
                              }`}
                              onClick={() => toggleEstado(cotizacion.id)} // Usamos el ID único
                            >
                              {estadosCeldas[cotizacion.id]}
                            </div>
                          </td>
                          <td
                            className="importe"
                            onClick={() => handleRowClick(cotizacion.id)}
                          >
                          {cotizacion.divisa} {parseFloat(cotizacion.total)
                              ?.toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    No hay resultados disponibles
                  </p>
                )}
              </>
            )}
            <Modal
              isOpen={mostrarFormulario}
              onRequestClose={closeModal}
              contentLabel="Nueva Cotización"
              style={styleForm}
            >
              <button onClick={closeModal} className="cerrar-button">
                <i className="bi bi-x-lg"></i>
              </button>
              <CotizacionForm
                clientes={clientes}
                guardarCotizacion={guardarCotizacion}
                modoEdicion={modoEdicion}
                cotizacion={cotizacion}
                numeroCotizacion={numeroCotizacion}
                onFormDirtyChange={handleFormDirtyChange}
              />
            </Modal>

            {/*  {showNuevoButton && (
            <button className="action-button2" onClick={openFormulario}>
              + Nuevo
            </button>
          )}*/}
          </div>
        )}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={cerrarModalPrevia}
          contentLabel="Vista Previa"
          style={customStyles}
        >
          {cotizacionSeleccionada && (
            <PreviaCotizacion
              cotizacion={cotizacionSeleccionada}
              numeroCotizacion={cotizacionSeleccionada.numeroCotizacion}
              clientes={clientes}
              cerrarPrevia={cerrarModalPrevia}
            />
          )}
        </Modal>

        <div
          className={`resumen-container ${
            selectedCotizacionId ? "active" : ""
          }`}
        >
          <ResumenCotizacion
            cotizacion={
              cotizaciones &&
              cotizaciones.find(
                (cotizacion) => cotizacion.id === selectedCotizacionId
              )
            }
            isOpen={resumenVisible}
            onClose={() => {
              setResumenVisible(false);
              setSelectedCotizacionId(null);
              setShowBandeja(false);
            }}
            numeroCotizacion={
              cotizacionSeleccionada
                ? cotizacionSeleccionada.numeroCotizacion
                : null
            }
            clientes={clientes}
            mostrarBotonNuevo={false}
          />
        </div>
      </div>
    </>
  );
}

export default TablaCotizaciones;