import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import "moment/locale/es"; // Importamos el idioma español
import {
  getFirestore,
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import Nav from "./Nav";
import Spinner from "./Spinner"; // Importar tu componente Spinner
import Chart from "./Chart";
import Modal from "react-modal";
import Swal from "sweetalert2";

Modal.setAppElement("#root");

// Configura el localizador de fechas usando moment.js
moment.locale("es");
const localizer = momentLocalizer(moment);

const Dashboard = () => {
  /*const [expanded, setExpanded] = useState(false);*/
  const [events, setEvents] = useState([]); // Array para los eventos del calendario
  const [currentDate, setCurrentDate] = useState(moment());
  const [view, setView] = useState("month");
  const [showBackButton, setShowBackButton] = useState(false);
  const [proximasAVencer, setProximasAVencer] = useState([]);
  const [proximosEventos, setProximosEventos] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para controlar la carga de datos
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    from: getCurrentDateTime(),
    to: getCurrentDateTime(),
    color: "#229954", // Inicializar el color en verde para los nuevos eventos
  });
  const [filter, setFilter] = useState("week");
  const [showOptions, setShowOptions] = useState(null);

  const handleViewChange = (newView) => {
    setView(newView);
    if (newView === "month") {
      setShowBackButton(false);
    } else {
      setShowBackButton(true);
    }
  };

  const handleSelectSlot = (slotInfo) => {
    if (view === "month") {
      setCurrentDate(moment(slotInfo.start)); // Usa `moment` para el objeto de fecha
      setView("day"); // Cambia a la vista diaria
      setShowBackButton(true);
    }
  };

  const handleSelectEvent = (event) => {
    setCurrentDate(moment(event.start)); // Cambia la fecha actual a la del evento seleccionado
    setView("day"); // Cambia la vista a "day"
    setShowBackButton(true);
  };

  const handleNavigateBackToMonth = () => {
    setView("month");
    setShowBackButton(false);
  };

  const toggleOptions = (eventId) => {
    setShowOptions(showOptions === eventId ? null : eventId);
  };

  const handleEditEvent = (evento) => {
    setNewEvent(evento);
    setModalIsOpen(true);
    setShowOptions(null); // Ocultar el menú de opciones después de seleccionar
  };

  const handleUpdateEvent = async () => {
    try {
      const firestore = getFirestore();
      const eventoRef = doc(firestore, "eventos", newEvent.id);
      await updateDoc(eventoRef, {
        title: newEvent.title,
        from: newEvent.from,
        to: newEvent.to,
      });

      // Actualizar el estado local
      setEvents((prevEvents) =>
        prevEvents.map((event) =>
          event.id === newEvent.id ? { ...newEvent } : event
        )
      );

      Swal.fire({
        title: "Evento Actualizado",
        text: "El evento ha sido actualizado exitosamente.",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });

      handleCloseModal();
    } catch (error) {
      console.error("Error al actualizar evento:", error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      // Mostrar la alerta de confirmación
      const result = await Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción eliminará el evento de forma permanente.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma, proceder con la eliminación
      if (result.isConfirmed) {
        const firestore = getFirestore();
        await deleteDoc(doc(firestore, "eventos", eventId));

        // Eliminar el evento del estado local
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== eventId)
        );

        Swal.fire({
          title: "Evento Eliminado",
          text: "El evento ha sido eliminado exitosamente.",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        setShowOptions(null);
      }
    } catch (error) {
      console.error("Error al eliminar evento:", error);
    }
  };

  const showScroll = proximosEventos.length > 3;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      borderRadius: "10px",
      border: "1px solid #ccc",
      boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
      fontFamily: "Montserrat, sans-serif",
      zIndex: 9999,
      width: "25%",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      zIndex: 9999,
    },
    input: {
      width: "100%",
      padding: "8px",
      marginBottom: "20px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      boxSizing: "border-box",
    },
    buttonAgregar: {
      padding: "10px 10px",
      marginTop: "100px",
      borderRadius: "5px",
      border: "none",
      backgroundColor: "#007bff",
      color: "white",
      fontSize: "12px",
      cursor: "pointer",
      position: "absolute",
      top: "20px",
      left: "80px",
    },
    button: {
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "5px",
      border: "none",
      backgroundColor: "#007bff",
      color: "white",
      fontSize: "14px",
      cursor: "pointer",
    },
    buttonCancel: {
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "5px",
      border: "none",
      backgroundColor: "#6c757d",
      color: "white",
      fontSize: "14px",
      cursor: "pointer",
    },
  };

  const handleAddEvent = async () => {
    try {
      // Guardar el nuevo evento en Firestore
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const docRef = await addDoc(eventosRef, {
        ...newEvent,
        from: newEvent.to,
      });

      // Agregar el nuevo evento al estado local con el id generado por Firestore
      const eventWithId = {
        ...newEvent,
        id: docRef.id,
        key: `evento_${docRef.id}`,
        from: newEvent.to,
      };
      setEvents((prevEvents) => [...prevEvents, eventWithId]);

      // Mostrar alerta de éxito
      Swal.fire({
        title: "Evento Registrado",
        text: "El evento ha sido registrado exitosamente.",
        icon: "success",
        showConfirmButton: false, // No mostrar el botón de confirmación
        timer: 2000, // Cerrar automáticamente después de 2 segundos
      });

      // Cerrar el modal
      handleCloseModal();
    } catch (error) {
      console.error("Error al agregar evento:", error);
    }
  };

  function getCurrentDateTime() {
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset()); // Ajuste para zona horaria
    return now.toISOString().slice(0, 16); // Formato para input datetime-local
  }

  const handleOpenModal = () => {
    const currentDateTime = getCurrentDateTime();
    setNewEvent({
      title: "",
      from: currentDateTime,
      to: currentDateTime,
      color: "#229954",
    });
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    setNewEvent({ ...newEvent, [e.target.name]: e.target.value });
  };

  /*const toggleExpand = () => {
    setExpanded(!expanded);
  };*/

  useEffect(() => {
    const fetchCotizaciones = async () => {
      const firestore = getFirestore();
      const cotizacionesRef = collection(firestore, "cotizaciones");
      const unsubscribeCotizaciones = onSnapshot(
        cotizacionesRef,
        (snapshot) => {
          const cotizaciones = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Filtrar y ordenar cotizaciones
          const proximas = cotizaciones
            .filter((cotizacion) =>
              moment(cotizacion.fechaVencimiento).isSameOrAfter(
                moment().startOf("day")
              )
            )
            .sort(
              (a, b) => moment(a.fechaVencimiento) - moment(b.fechaVencimiento)
            );

          setProximasAVencer(proximas.slice(0, 6)); // Limitar la lista a 6 fechas próximas

          // Crear eventos para cotizaciones
          const cotizacionesEvents = proximas.map((cotizacion) => ({
            id: `cotizacion-${cotizacion.id}`,
            title: `Cotización #${cotizacion.numeroCotizacion}`,
            start: moment(cotizacion.fechaVencimiento).startOf("day").toDate(),
            end: moment(cotizacion.fechaVencimiento).endOf("day").toDate(),
            allDay: true,
            resource: "cotizacion",
            style: {
              backgroundColor: "blue",
              padding: "auto",
              fontSize: "12px",
              width: "80%",
              margin: "0 auto",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              height: "auto"
            },
          }));

          // Actualizar el estado de eventos
          setEvents((prevEvents) => {
            const eventMap = new Map(
              prevEvents
                .filter((event) => !event.id.startsWith("cotizacion-"))
                .map((event) => [event.id, event])
            );
            cotizacionesEvents.forEach((event) =>
              eventMap.set(event.id, event)
            );
            return Array.from(eventMap.values());
          });
        }
      );

      return () => unsubscribeCotizaciones();
    };

    const fetchEventos = async () => {
      const firestore = getFirestore();
      const eventosRef = collection(firestore, "eventos");
      const unsubscribeEventos = onSnapshot(eventosRef, (snapshot) => {
        const eventos = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filtrar y ordenar eventos
        const proximos = eventos
          .filter((evento) =>
            moment(evento.to).isSameOrAfter(moment().startOf("day"))
          )
          .sort((a, b) => moment(a.to) - moment(b.to));

        setProximosEventos(proximos.slice(0, 6)); // Limitar la lista a 6 eventos próximos

        // Crear eventos para el calendario
        const eventosCalendario = proximos.map((evento) => ({
          id: `evento-${evento.id}`,
          title: evento.title,
          start: moment(evento.to).startOf("day").toDate(),
          end: moment(evento.to).endOf("day").toDate(),
          allDay: true,
          resource: "evento",
          style: {
            backgroundColor: "#229954",
            padding: "auto",
            fontSize: "12px",
            width: "80%",
            margin: "0 auto",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: "auto"
          },
        }));

        // Actualizar el estado de eventos
        setEvents((prevEvents) => {
          const eventMap = new Map(
            prevEvents
              .filter((event) => !event.id.startsWith("evento-"))
              .map((event) => [event.id, event])
          );
          eventosCalendario.forEach((event) => eventMap.set(event.id, event));
          return Array.from(eventMap.values());
        });

        setLoading(false);
      });

      return () => unsubscribeEventos();
    };

    // Obtener fechas festivas
    const getFechasFestivas = () => {
      const fechasFestivasBase = [
        { title: "Año Nuevo", month: "01", day: "01", color: "#de2e03" },
        {
          title: "Día de la Constitución",
          month: "02",
          day: "05",
          color: "#de2e03",
        },
        {
          title: "Natalicio de Benito Juárez",
          month: "03",
          day: "21",
          color: "#de2e03",
        },
        { title: "Día del Trabajo", month: "05", day: "01", color: "#de2e03" },
        {
          title: "Independencia de México",
          month: "09",
          day: "16",
          color: "#de2e03",
        },
        {
          title: "Transición del Poder Ejecutivo",
          month: "10",
          day: "01",
          color: "#de2e03",
        },
        {
          title: "Revolución Mexicana",
          month: "11",
          day: "20",
          color: "#de2e03",
        },
        { title: "Navidad", month: "12", day: "25", color: "#de2e03" },
      ];

      const generarFechasFestivas = (años) => {
        const fechasFestivas = [];
        años.forEach((año) => {
          fechasFestivasBase.forEach((festivo) => {
            const fecha = moment(
              `${año}-${festivo.month}-${festivo.day}`,
              "YYYY-MM-DD"
            ).toDate();
            fechasFestivas.push({
              id: `festivo-${festivo.title}-${año}`,
              title: festivo.title,
              start: fecha,
              end: fecha,
              allDay: true,
              resource: "festivo",
              style: {
                backgroundColor: "#de2e03",
                padding: "auto",
                fontSize: "12px",
                width: "80%",
                margin: "0 auto",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: "auto"
              },
            });
          });
        });
        return fechasFestivas;
      };

      const años = Array.from(
        { length: 6 },
        (_, i) => new Date().getFullYear() + i
      );
      return generarFechasFestivas(años);
    };

    // Obtener fechas festivas y fusionarlas con los eventos existentes
    const fechasFestivas = getFechasFestivas();
    setEvents((prevEvents) => [
      ...prevEvents.filter((event) => !event.id.startsWith("festivo-")),
      ...fechasFestivas,
    ]);

    // Ejecutar las funciones de carga de cotizaciones y eventos
    fetchCotizaciones();
    fetchEventos();
  }, []);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const filteredEvents = proximosEventos.filter((evento) => {
    const now = moment();

    if (filter === "today") {
      return moment(evento.to).isSame(now, "day");
    } else if (filter === "week") {
      const startOfWeek = moment().startOf("week");
      const endOfNextWeek = moment().add(1, "weeks").endOf("week");
      return moment(evento.to).isBetween(
        startOfWeek,
        endOfNextWeek,
        "day",
        "[]"
      );
    } else if (filter === "month") {
      const startOfNextMonth = moment().add(1, "month").startOf("month");
      const endOfNextMonth = moment().add(1, "month").endOf("month");
      return moment(evento.to).isBetween(
        startOfNextMonth,
        endOfNextMonth,
        "day",
        "[]"
      );
    }

    return true;
  });

  // Mostrar Spinner mientras los datos están cargando
  if (loading) {
    return <Spinner />;
  }

  const CustomToolbar = ({ onNavigate }) => (
    <div className="rbc-toolbar">
      <div
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <div style={{ marginRight: "auto" }}>
          <span

            style={{
              fontSize: "16px",
              padding: "15px",
            }}
          >
            {
              <i
                className="bi bi-arrow-left-circle"
                style={{ cursor: "pointer" }}
                onClick={() => onNavigate("PREV")}
              ></i>
            }
          </span>
        </div>
        <div className="month-year-container">
          <h2
            style={{ margin: "0", display: "inline-block", fontSize: "16px" }}
          >
            {currentDate.format("MMMM").charAt(0).toUpperCase() +
              currentDate.format("MMMM").slice(1)}
          </h2>
          <h2
            style={{
              margin: "0",
              display: "inline-block",
              fontSize: "16px",
              marginLeft: "0.5rem",
            }}
          >
            {currentDate.format("YYYY")}
            {/* Formato de fecha para mostrar el año */}
          </h2>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <span
            type="button"
            style={{
              fontSize: "16px",
              padding: "15px",
            }}
          >
            {
              <i
                className="bi bi-arrow-right-circle"
                style={{ cursor: "pointer" }}
                onClick={() => onNavigate("NEXT")}
              ></i>
            }
          </span>
        </div>
      </div>
    </div>
  );

  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, "dddd", culture).charAt(0).toUpperCase(),
  };

  return (
    <>
      <Nav
        proximasAVencer={proximasAVencer}
        proximosEventos={proximosEventos}
      />

      <div style={{ marginBottom: "2rem" }}>
        <div className="filters-container">
          <div className="filter">
            Timeframe: All-time <i className="bi bi-chevron-down" style={{paddingLeft: "5px"}}></i>
          </div>
          <div className="filter">
            Vendedor: Todos <i className="bi bi-chevron-down" style={{paddingLeft: "5px"}}></i>
          </div>
        </div>

        <div className="containerEstadisticas">
          <div className="card2 blue">
            <h3>Ingresos Mensuales</h3>
            <div className="number">3,846</div>
            <div className="percent">28.36%</div>
            <div className="graph">
              <div className="bar" style={{ height: "30%" }}></div>
              <div className="bar" style={{ height: "40%" }}></div>
              <div className="bar" style={{ height: "50%" }}></div>
              <div className="bar" style={{ height: "60%" }}></div>
              <div className="bar" style={{ height: "70%" }}></div>
              <div className="bar" style={{ height: "80%" }}></div>
              <div className="bar" style={{ height: "90%" }}></div>
              <div className="bar blue" style={{ height: "100%" }}></div>
            </div>
          </div>
          <div className="card2 red">
            <h3>Avg. Engagement Rate</h3>
            <div className="number">86.5%</div>
            <div className="percent">13.30%</div>
            <div className="graph">
              <div className="bar" style={{ height: "40%" }}></div>
              <div className="bar" style={{ height: "50%" }}></div>
              <div className="bar" style={{ height: "60%" }}></div>
              <div className="bar" style={{ height: "70%" }}></div>
              <div className="bar" style={{ height: "80%" }}></div>
              <div className="bar" style={{ height: "90%" }}></div>
              <div className="bar" style={{ height: "100%" }}></div>
              <div className="bar red" style={{ height: "20%" }}></div>
            </div>
          </div>
          <div className="card2 green">
            <h3>Avg. Reach</h3>
            <div className="number">$ 12,874</div>
            <div className="percent">13.30%</div>
            <div className="graph">
              <div className="bar" style={{ height: "60%" }}></div>
              <div className="bar" style={{ height: "70%" }}></div>
              <div className="bar" style={{ height: "80%" }}></div>
              <div className="bar" style={{ height: "90%" }}></div>
              <div className="bar" style={{ height: "50%" }}></div>
              <div className="bar" style={{ height: "90%" }}></div>
              <div className="bar" style={{ height: "80%" }}></div>
              <div className="bar green" style={{ height: "70%" }}></div>
            </div>
          </div>
          <div className="card2 orange">
            <h3>Site Visitors</h3>
            <div className="number">$ 7,354</div>
            <div className="percent">10.38%</div>
            <div className="graph">
              <div className="bar" style={{ height: "55%" }}></div>
              <div className="bar" style={{ height: "65%" }}></div>
              <div className="bar" style={{ height: "75%" }}></div>
              <div className="bar" style={{ height: "85%" }}></div>
              <div className="bar" style={{ height: "95%" }}></div>
              <div className="bar" style={{ height: "85%" }}></div>
              <div className="bar" style={{ height: "75%" }}></div>
              <div className="bar orange" style={{ height: "65%" }}></div>
            </div>
          </div>
        </div>

        <Chart />

        <div className="archivos-container">
          <h3>Archivos Recientes</h3>
          <div className="filters">
            <label htmlFor="filter1"></label>
            <select id="filter1" className="filter-select">
              <option value="">Fecha</option>
              <option value="option1">Opción 1</option>
              <option value="option2">Opción 2</option>
              <option value="option3">Opción 3</option>
            </select>

            <label htmlFor="filter2"></label>
            <select id="filter2" className="filter-select">
              <option value="">Filtrar tabla:</option>
              <option value="option1">Opción 1</option>
              <option value="option2">Opción 2</option>
              <option value="option3">Opción 3</option>
            </select>
          </div>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>NOMBRE</th>
                  <th>FECHA</th>
                  <th>ESTATUS</th>
                  <th>TOTAL</th>
                  <th>USUARIO</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Laboratorios Pisa Sa de CV</td>
                  <td>20-05-2024</td>
                  <td>
                    <div className="status en-proceso">En proceso</div>
                  </td>
                  <td>12,509.00</td>
                  <td>Víctor García</td>
                  <td>
                    <button className="button1">Ver</button>
                    <button className="delete">
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Laboratorios Pisa Sa de CV</td>
                  <td>11-03-2024</td>
                  <td>
                    <div className="status pagada">Pagada</div>
                  </td>
                  <td>9,001.80</td>
                  <td>Magno Hernández</td>
                  <td>
                    <button className="button1">Ver</button>
                    <button className="delete">
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td>Chupaletas Sa de CV</td>
                  <td>08-01-2024</td>
                  <td>
                    <div className="status cancelado">Cancelado</div>
                  </td>
                  <td>89,169.00</td>
                  <td>Víctor García</td>
                  <td>
                    <button className="button1">Ver</button>
                    <button className="delete">
                      <i className="bi bi-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="dashboard-container">
        {/* Contenedor del calendario */}
        <div className="calendar-container">
  <div className="calendar-header"></div>
  <div className="calendar-content">
    {showBackButton && (
      <div style={{ margin: "0", position: "relative" }}>
        <button
          style={{
            position: "absolute",
            top: "-18px", // Ajusta este valor para elevar el botón
            left: "-5%",
            textAlign: "left",
            display: "flex",
            fontWeight: "bold",
          }}
          onClick={handleNavigateBackToMonth}
        >
          <i style={{ fontSize: "20px" }} className="bi bi-arrow-left"></i>
        </button>
      </div>
    )}
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      formats={formats}
      style={{ width: "100%", height: "110%" }}
      selectable={true}
      components={{
        toolbar: CustomToolbar,
      }}
      eventPropGetter={(event) => ({
        style: event.style || {},
      })}
      onNavigate={(newDate) => {
        setCurrentDate(moment(newDate));
      }}
      views={["month", "week", "day"]}
      view={view}
      date={currentDate.toDate()}
      onView={handleViewChange}
      onSelectSlot={handleSelectSlot}
      onSelectEvent={handleSelectEvent}
    />
  </div>
</div>

        {/* Panel de próximos eventos */}
        <div className="upcoming-events">
          <div className="events-container">
            <div className="events-header">
              <h3 className="events-title">Próximos Eventos</h3>
              <select
                className="events-date-selector"
                value={filter}
                onChange={handleFilterChange}
              >
                <option value="today">
                  Hoy, {moment().format("DD MMM YYYY")}
                </option>
                <option value="week">Próxima Semana</option>
                <option value="month">Próximo Mes</option>
              </select>
            </div>
            <ul className={`events-list ${showScroll ? "scrollable" : ""}`}>
              {filteredEvents.length === 0 ? (
                <p style={{ fontSize: "14px" }}>No hay eventos aún</p>
              ) : (
                <>
                  {filteredEvents.slice(0, 3).map((evento) => (
                    <li key={evento.id} className="event-item">
                      <div className="event-details">
                        <div className="event-header">
                          <span className="event-title">{evento.title}</span>
                          <div className="event-actions">
                            <button
                              className="event-options btn btn-link"
                              onClick={() => toggleOptions(evento.id)}
                            >
                              <i className="bi bi-three-dots icon-large"></i>
                            </button>
                            {showOptions === evento.id && (
                              <div className="event-options-menu">
                                <button onClick={() => handleEditEvent(evento)}>
                                  Editar
                                </button>
                                <button
                                  onClick={() => handleDeleteEvent(evento.id)}
                                >
                                  Eliminar
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                        <span className="event-date-time">
                          {moment(evento.to).format("DD/MM/YYYY hh:mm a")}
                        </span>
                      </div>
                    </li>
                  ))}

                  {filteredEvents
                    .slice(3)
                    .filter(
                      (evento) => moment(evento.to).diff(moment(), "days") < 15
                    )
                    .map((evento) => (
                      <li key={evento.id} className="event-item">
                        <div className="event-details">
                          <div className="event-header">
                            <span className="event-title">{evento.title}</span>
                            <div className="event-actions">
                              <button
                                className="event-options btn btn-link"
                                onClick={() => toggleOptions(evento.id)}
                              >
                                <i className="bi bi-three-dots icon-large"></i>
                              </button>
                              {showOptions === evento.id && (
                                <div className="event-options-menu">
                                  <button
                                    onClick={() => handleEditEvent(evento)}
                                  >
                                    Editar
                                  </button>
                                  <button
                                    onClick={() => handleDeleteEvent(evento.id)}
                                  >
                                    Eliminar
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <span className="event-date-time">
                            {moment(evento.to).format("DD/MM/YYYY hh:mm a")}
                          </span>
                        </div>
                      </li>
                    ))}
                </>
              )}
            </ul>
          </div>
          <button className="create-event-button" onClick={handleOpenModal}>
            <i className="bi bi-plus-circle icon-large"></i> Crear Evento Nuevo
          </button>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <h2
          style={{ fontFamily: "Montserrat, sans-serif", textAlign: "center" }}
        >
          {newEvent.id ? "Editar Evento" : "Agregar Evento"}
        </h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            newEvent.id ? handleUpdateEvent() : handleAddEvent();
          }}
        >
          <label>
            Título:
            <input
              type="text"
              name="title"
              value={newEvent.title}
              onChange={handleChange}
              style={customStyles.input}
              required
            />
          </label>
          <label>
            Fecha y hora de finalización:
            <input
              type="datetime-local"
              name="to"
              value={newEvent.to}
              onChange={handleChange}
              style={customStyles.input}
            />
          </label>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button type="submit" style={customStyles.button}>
              {newEvent.id ? "Guardar Cambios" : "Agregar"}
            </button>
            <button
              type="button"
              onClick={handleCloseModal}
              style={customStyles.buttonCancel}
            >
              Cancelar
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default Dashboard;